import React from 'react';
import Select, { createFilter } from 'react-select';

import { setSelectorDirectionClass } from '../../helper/functions';

import './ReactSelectWrapper.scss';

const ReactSelectWrapper = ({
  className = '',
  options,
  onChange,
  placeholder,
  filterOption,
  onMenuClose,
  onMenuOpen,
  isSearchable = false,
  isDisabled = false,
  isMulti = false,
  value = null,
  hasHover = false,
  children,
}) => {
  return (
    <div className="select-wrapper">
      <Select
        className={`select-container ${className} ${hasHover ? 'hover' : ''}`}
        placeholder={placeholder}
        options={options}
        isSearchable={isSearchable}
        onChange={onChange}
        onMenuClose={onMenuClose || setSelectorDirectionClass}
        onMenuOpen={onMenuOpen || setSelectorDirectionClass}
        value={value}
        filterOption={
          filterOption ||
          createFilter({
            matchFrom: 'any',
            stringify: (option) => `${option.label}`,
          })
        }
        isDisabled={isDisabled}
        isMulti={isMulti}
        classNamePrefix="subject-select"
        menuPlacement="auto"
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'transparent',
          },
        })}
      />
      {children}
    </div>
  );
};

export default ReactSelectWrapper;
