export function generateTheme() {
  const elements = document.getElementsByClassName('layout');
  const hasConfig = clientConfig && clientConfig.colors;
  if (!hasConfig && !elements.length) {
    return;
  }
  const root = elements[0];

  root.style.setProperty('--mainColor', clientConfig.colors.primary);
  //root.style.setProperty('--secondaryColor', clientConfig.colors.secondary);
  //root.style.setProperty('--iconColor', clientConfig.colors.icon);
  root.style.setProperty('--headerColor', clientConfig.colors.header);
  root.style.setProperty('--headerTextColor', clientConfig.colors.headerText);
  root.style.setProperty('--svgHoverColor', clientConfig.colors.svgHoverColor);
  root.style.setProperty('--checkboxColor', clientConfig.colors.checkbox);
  root.style.setProperty('--profileDropdownColor', clientConfig.colors.profileDropdown);
}
