import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { I18n } from 'react-redux-i18n';

import Comment from '../Comment';
import StatusSelector from '../StatusSelector';
import Svg from '../Svg';
import ResponsiveTable from '../ResponsiveTable';

import { translate } from '../../helper/functions';

import './AdminDocumentTable.scss';

import eye from '../../assets/eye.svg';
import eye_no from '../../assets/eye_no.svg';
import document from '../../assets/document_icon.svg';
import upload from '../../assets/upload_icon.svg';
import download from '../../assets/download_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import loader from '../../assets/loader_circle_icon.svg';

class AdminDocumentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpenByDocId: '',
    };
    this.headerColumns = [
      { name: I18n.t('document-library/table-header-1'), class: 'document-name', sortName: 'label' },
      { name: I18n.t('document-library/table-header-2'), class: 'document-icon', sortName: 'generatedFilePath' },
      { name: I18n.t('document-library/table-header-3'), class: 'document-icon', sortName: 'dataFlow' },
      { name: I18n.t('document-library/table-header-6'), class: 'document-status', sortName: 'state' },
      { name: I18n.t('document-library/table-header-8'), class: 'activity' },
      { name: I18n.t('document-library/table-header-9'), class: 'message' },
    ];
  }

  clickOnDropdown = (docId) => {
    let newDropdownToOpen = this.state.dropdownOpenByDocId === docId ? '' : docId;
    this.setState((state) => {
      return {
        dropdownOpenByDocId: newDropdownToOpen,
      };
    });
  };

  handleUploadClick = (e) => {
    this.setState({ dropdownOpenByDocId: '' });
    this.props.uploadDocument(e);
  };

  filterDocuments = (documents) => {
    const search = this.props.search;
    const regex = /<(.*?)>/g;
    return documents.filter((item) =>
      translate(item.label).replace(regex, '').toLowerCase().includes(search.toLowerCase())
    );
  };

  handleSelectChange = (event, docId) => {
    const { value } = event;
    this.props.setDocumentSettings('state', value, docId);
  };

  render() {
    const {
      documents,
      downloadDocument,
      ableToUploadAndDelete,
      setDocumentSettings,
      openDeletePopup,
      submissionId,
    } = this.props;

    return (
      <ResponsiveTable
        className="documents-admin-table-container"
        headerColumns={this.headerColumns}
        data={this.filterDocuments(documents)}
        defaultSort="label"
        isAscending={true}
        renderRow={(d, options) => {
          return (
            <div className={'table-row'} key={d._id}>
              <div className={'column document-name sticky ' + d.state}>
                <div className="img-container eye">
                  <Svg
                    src={d.hidden ? eye_no : eye}
                    hasHover={true}
                    onClick={() => setDocumentSettings('hidden', d.hidden ? false : true, d._id)}
                  />
                </div>
                <div dangerouslySetInnerHTML={{ __html: translate(d.label) }} />
              </div>
              <div className="column document-icon">
                {d.dataFlow !== 'upload' &&
                  (d.generatedFilePath ? (
                    <Svg onClick={() => downloadDocument(d)} className={'active'} src={download} hasHover={true} />
                  ) : (
                    <Svg className={'download rotating'} src={loader} hasHover={true} />
                  ))}
              </div>
              <div className={`column document-icon ${d.dataFlow === 'download' ? 'inactive' : ''}`}>
                {d.filePath ? (
                  <Svg className="doc-icon" src={document} hasHover={true} onClick={() => downloadDocument(d, true)} />
                ) : d.uploading ? (
                  <Svg src={loader} className="rotating" hasHover={true} />
                ) : ableToUploadAndDelete ? (
                  <label htmlFor={'file-upload' + options.index} className="custom-file-upload">
                    <Svg src={upload} hasHover={true} />
                    <input
                      id={'file-upload' + options.index}
                      type="file"
                      name={d._id}
                      onChange={(e) => this.handleUploadClick(e)}
                    />
                  </label>
                ) : null}
                {d.filePath && ableToUploadAndDelete && (
                  <img onClick={() => openDeletePopup(d)} className="delete-icon" src={delete_icon} />
                )}
              </div>
              <div className="column document-status">
                <div className="status">
                  <StatusSelector
                    isDisabled={this.props.submission.completed}
                    state={d.state}
                    handleSelectChange={(event) => this.handleSelectChange(event, d._id)}
                  />
                </div>
              </div>
              <div className="column activity switcher">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={d.readonly ? true : false}
                    onChange={() => setDocumentSettings('readonly', d.readonly ? false : true, d._id)}
                  />
                  <span className={`slider round ${d.readonly ? 'off' : 'on'}`}></span>
                </label>
                <Translate
                  className={`switcher-status ${d.readonly ? 'off' : 'on'}`}
                  value={d.readonly ? 'datacheck/inactive' : 'datacheck/active'}
                />
              </div>
              <div className="column message">
                {!d.hidden && (
                  <Comment
                    comment={d.comment}
                    submissionId={submissionId}
                    target={{ documentId: d._id }}
                    targetName={translate(d.label)}
                    targetUrl={'/documents/comment'}
                  />
                )}
              </div>
            </div>
          );
        }}
      ></ResponsiveTable>
    );
  }
}

AdminDocumentTable.propTypes = {
  documents: PropTypes.array,
  uploadDocument: PropTypes.func,
  downloadDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  descriptionShown: PropTypes.bool,
  search: PropTypes.string,
  setDocumentSettings: PropTypes.func,
};

export default AdminDocumentTable;
