import React, { useState, useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { I18n, Translate } from 'react-redux-i18n';

import AdminForm from '../AdminForm';
import Authorization from '../Authorization';
import Popup from '../Popup';
import SubHeader from '../SubHeader';
import TextWithTooltip from '../TextWithTooltip';
import Svg from '../Svg';

import './EditAdminDetails.scss';

import saveIcon from '../../assets/save.svg';
import resetIcon from '../../assets/cancel.svg';

const reducer = (state, { field, value }) => {
  return {
    ...state,
    [field]: value,
  };
};

const EditAdminDetails = (props) => {
  const { token, user, id, setData } = props;
  const [formState, dispatch] = useReducer(reducer, {
    name: user.name,
    email: user.email,
    claim: user.claim,
    role: user.role,
  });
  const [popup, setPopup] = useState({
    shown: false,
    message: '',
  });
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (
      JSON.stringify({ name: user.name, email: user.email, claim: user.claim, role: user.role }) ===
      JSON.stringify(formState)
    ) {
      setFormValid(false);
    }
  }, [formState]);

  const editAdmin = async () => {
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    let message = '';
    try {
      const response = await axios.put(`/admin/users/${id}`, formState, { headers });
      if (response.status === 200) {
        message = I18n.t('edit-admin-details/success');
        setFormValid(false);
      } else {
        message = I18n.t('edit-admin-details/fail');
      }
    } catch (e) {
      message = I18n.t('edit-admin-details/fail');
      console.error(e);
    }
    setPopup({ shown: true, message });
  };
  const onChange = (e) => dispatch({ field: e.target.name, value: e.target.value });
  const cancelChanges = () => Object.keys(formState).forEach((key) => dispatch({ field: key, value: user[key] }));

  return (
    <div className="edit-admin-details-container container hide-scrollbar">
      <SubHeader>
        <div className="column">
          <div className="title">
            <TextWithTooltip textWithOverflow={user.name} />
          </div>
        </div>
        <div className="column">
          <button className="subheader-button" disabled={!formValid} onClick={cancelChanges}>
            <Svg src={resetIcon} otherColor="#FFFFFF" />
            <Translate value="form/cancel" />
          </button>
          <button className="subheader-button" disabled={!formValid} onClick={editAdmin}>
            <Svg src={saveIcon} otherColor="#FFFFFF" />
            <Translate value="form/save" />
          </button>
        </div>
      </SubHeader>
      <AdminForm setFormValid={setFormValid} user={formState} onChange={onChange} />
      <Popup
        popupShown={popup.shown}
        okHandler={() => {
          setPopup({ shown: false, message: '' });
          setData(formState);
        }}
        popupTitle={I18n.t('user-management/admins/change-details')}
      >
        {popup.message}
      </Popup>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}

export default Authorization(connect(mapStateToProps, null)(EditAdminDetails), ['edit-admin']);
