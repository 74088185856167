import React, { useState } from 'react';

import Svg from '../Svg';
import WrappedLink from '../WrappedLink';

import './BackButton.scss';

import prev from '../../assets/blue_back_button.svg';

const BackButton = (props) => {
  const { title, backUrl, className } = props;
  const [ mouseOn, setMouseOn ] = useState(false);
  const additionalClassName = className ? className : null;

  return (<div
      className={"navigate-back " + additionalClassName}
      onMouseOver={() => setMouseOn(true)}
      onMouseOut={() => setMouseOn(false)}
    >
      <WrappedLink to={backUrl}>
        <Svg
          src={prev}
          hasHover={true}
          otherColor={mouseOn ? clientConfig.colors.svgHoverColor : null}>
        </Svg>
        <span>{title}</span>
      </WrappedLink>
    </div>
  )
};

export default BackButton;