import React, { useState, useLayoutEffect, useRef, useEffect, useCallback } from 'react';

import TextWithTooltip from '../TextWithTooltip';
import Svg from '../Svg';

import { getSortFunctionWithDirection } from '../../helper/functions';

import './ResponsiveTable.scss';

import sort from '../../assets/sort.svg';
import ascending from '../../assets/sort_ascending_icon.svg';
import descending from '../../assets/sort_descending_icon.svg';

const ResponsiveTable = ({
  headerColumns,
  data,
  defaultSort = 'clientName',
  isAscending = true,
  className,
  renderRow = () => null,
}) => {
  const [propertyAndDirection, setPropertyAndDirection] = useState({
    property: defaultSort,
    isAscending,
  });
  const [stickyPosition, setStickyPosition] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef.current]);

  useLayoutEffect(() => {
    const resizeListener = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
      setTotalWidth(0);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const stickyRef = useCallback((node) => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width;
      setStickyPosition(width);
    }
  });
  const lastColumnRef = useCallback((node) => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width;
      setTotalWidth(node.offsetLeft + width);
    }
  });
  const sortedData = [...data].sort(
    getSortFunctionWithDirection(propertyAndDirection.property, propertyAndDirection.isAscending)
  );

  return (
    <div className={`responsive-table-container ${className || ''}`} ref={containerRef}>
      {/* extra wrapper div needed because of safari & position sticky https://stackoverflow.com/questions/57934803/workaround-for-a-safari-position-sticky-webkit-sticky-bug */}
      <div style={{ minWidth: totalWidth }}>
        <div className="table-header">
          {headerColumns.map((c, index) => (
            <div
              key={index}
              className={`column ${c.class} ${!index ? 'sticky' : ''}`}
              ref={!index ? stickyRef : index === headerColumns.length - 1 ? lastColumnRef : null}
              onClick={() => {
                c.sortName &&
                  setPropertyAndDirection({
                    property: c.sortName,
                    isAscending: !propertyAndDirection.isAscending,
                  });
              }}
              style={c.class.includes('sticky') && index && stickyPosition ? { left: stickyPosition } : null}
            >
              <TextWithTooltip textWithOverflow={c.name} />
              {c.sortName && c.sortName !== propertyAndDirection.property && (
                <Svg className="sort-svg" src={sort} otherColor="#000" />
              )}
              {c.sortName === propertyAndDirection.property && (
                <Svg
                  className="sorted-svg"
                  src={propertyAndDirection.isAscending ? ascending : descending}
                  otherColor="#000"
                />
              )}
            </div>
          ))}
        </div>
        <div className="table-content hide-scrollbar">
          {sortedData.map((d, index) => {
            return renderRow(d, { index, stickyPosition, containerWidth });
          })}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveTable;
