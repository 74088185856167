import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import InputWithError from '../InputWithError';
import Header from '../Header';
import Popup from '../Popup';
import WrappedLink from '../WrappedLink';

import * as authActions from '../../actions/auth';
import { formValidator } from '../../helper/functions';
import { calculateRoutePath } from '../../helper/navigation';

class LoginPage extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        email: '',
        password: '',
      },
      errors: {},
      popupShown: false,
      popupText: '',
      errorsVisible: false,
      browserHasAutofill: false,
    };
    this.headerLinks = [{ to: '/registration', translateKey: 'menu/registration' }];
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/');
    }
    setTimeout(() => {
      const hasAutoFillOnEmail =
        document.querySelector('#email') && document.querySelector('#email').matches(':-webkit-autofill');
      const hasAutoFillOnPassword =
        document.querySelector('#email') && document.querySelector('#password').matches(':-webkit-autofill');
      this.setState((state) => {
        return {
          ...state,
          browserHasAutofill: hasAutoFillOnEmail && hasAutoFillOnPassword,
        };
      });
    }, 500);
  }

  handleInputChange = (event) => {
    const { target } = event;
    this.setState((state) => {
      return {
        formData: {
          ...state.formData,
          [target.name]: target.value,
        },
        errors: { ...state.errors, ...formValidator(target.name, target.value) },
        browserHasAutofill: false,
        errorsVisible: false,
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.errors.password || this.state.errors.email) {
      this.setState((state) => {
        return {
          ...state,
          errorsVisible: true,
        };
      });
    } else {
      axios
        .post('/auth/login', { ...this.state.formData, type: 'email' })
        .then((response) => {
          this.props.login(response.data);
          Sentry.setUser({
            email: this.state.formData.email,
          });
          const navigateAfterLogin = localStorage.getItem('navigateAfterLogin');
          const usersMainRoute = this.props.userData && this.props.userData.mainRoute;
          if (navigateAfterLogin) {
            this.props.history.push(calculateRoutePath(usersMainRoute, navigateAfterLogin));
            localStorage.removeItem('navigateAfterLogin');
          } else {
            this.props.history.push('/');
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState((state) => {
            return {
              ...state,
              popupShown: true,
              popupText: e.response.data.error,
            };
          });
        });
    }
  };

  goBack() {
    this.props.history.goBack();
  }

  popupOkHandler = () => {
    this.setState((state) => {
      return {
        ...state,
        popupShown: false,
        popupText: '',
      };
    });
    this.props.history.push('/login');
  };

  render() {
    const { formData, errors, popupShown, popupText, errorsVisible, browserHasAutofill } = this.state;
    const formValid = formData.email.length > 0 && formData.password.length > 0 && !errors.email;

    return (
      <div className="authentication-page-content">
        <Popup popupShown={popupShown} popupImg="error" okHandler={this.popupOkHandler}>
          <span>{I18n.t(popupText)}</span>
        </Popup>
        <Header links={this.headerLinks} />
        <h1>
          <Translate value="login/title" />
        </h1>
        <div className="form-wrapper">
          <div className="form-container">
            <form onSubmit={this.handleSubmit}>
              <InputWithError
                placeholder={I18n.t('form/email')}
                name="email"
                type="email"
                id="email"
                value={formData.email}
                autoFilled={browserHasAutofill}
                onChange={this.handleInputChange}
                errorMsg={errorsVisible ? errors.email : ''}
              />
              <InputWithError
                placeholder={I18n.t('form/password')}
                name="password"
                type="password"
                id="password"
                value={formData.password}
                autoFilled={browserHasAutofill}
                onChange={this.handleInputChange}
                errorMsg={errorsVisible ? errors.password : ''}
              />
              <div className="button-container one-child">
                <button className="secondary" type="submit" disabled={!formValid && !browserHasAutofill}>
                  <Translate value="login/title" />
                </button>
              </div>
            </form>
          </div>
          <WrappedLink to={'forgottenpassword'}>
            <Translate className="forgotten-password" value="forgotten-password/title" />
          </WrappedLink>
          <div className="underline">
            <div className="line"></div>
          </div>
          <Translate className="create-new-account" value="login/not-registered-yet" />
          <div className="button-container">
            <WrappedLink className="button secondary" to={'/registration'}>
              <Translate value="registration/title" />
            </WrappedLink>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  login: PropTypes.func,
  authenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    userData: state.auth.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: authActions.login,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
