import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as entityThunks from '../../thunks/entity';
import axios from 'axios';
import { I18n, Translate } from 'react-redux-i18n';

import Authorization from '../Authorization';
import EntityTable from '../EntityTable';
import Filterbar from '../Filterbar';
import Popup from '../Popup';
import SubHeader from '../SubHeader';
import Svg from '../Svg';
import TextWithTooltip from '../TextWithTooltip';
import ViewOptions from '../ViewOptions';

import { calculateRoutePath } from '../../helper/navigation';
import { translate, filterBy } from '../../helper/functions';
import * as errorActions from '../../actions/error';

import './AllocatedEntities.scss';

import saveIcon from '../../assets/save.svg';
import resetIcon from '../../assets/cancel.svg';

const AllocatedEntities = (props) => {
  const {
    history,
    entities,
    token,
    isLoaded,
    authenticated,
    userData,
    fetchEntities,
    user,
    id,
    modules,
    throwError,
  } = props;
  const [entityList, setEntityList] = useState([]);
  const [filteredEntityList, setFilteredEntityList] = useState([]);
  const [allocated, setAllocated] = useState([]);
  const [unAllocated, setUnAllocated] = useState([]);
  const [popup, setPopup] = useState({
    shown: false,
  });
  const defaultSearchAndFilter = {
    search: '',
    activeProcess: false,
    state: '',
    module: '',
  };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);

  useEffect(() => {
    if (!isLoaded && authenticated) {
      fetchEntities();
    }
  }, []);
  useEffect(() => {
    if (entities.length) {
      initEntityList();
    }
  }, [entities]);
  useEffect(() => {
    setFilteredEntityList(
      entityList.filter((e) =>
        filterBy(
          e,
          [
            { type: 'text', itemProperties: ['data.name'], searchProperty: 'search' },
            { type: 'boolean', itemProperties: ['activeSubmission'], searchProperty: 'activeProcess' },
            { type: 'selector', itemProperties: ['state'], searchProperty: 'state' },
            { type: 'selector', itemProperties: ['creatorModule._id'], searchProperty: 'module' },
          ],
          searchAndFilter
        )
      )
    );
  }, [entityList, searchAndFilter]);

  const usersMainRoute = userData && userData.mainRoute;
  const goToEntity = (entity) =>
    history.push(calculateRoutePath(usersMainRoute, '/client-management/entity/' + entity._id + '/submissions'));
  const toggleAllocated = (entity) => {
    const { _id } = entity;
    if (!entity.pendingAllocation) {
      setToAllocated(_id);
    } else {
      setToUnAllocated(_id);
    }
  };
  const setToAllocated = (entityId) => {
    if (unAllocated.includes(entityId)) {
      setUnAllocated(unAllocated.filter((e) => e !== entityId));
    } else {
      setAllocated([...allocated, entityId]);
    }
  };
  const setToUnAllocated = (entityId) => {
    if (allocated.includes(entityId)) {
      setAllocated(allocated.filter((e) => e !== entityId));
    } else {
      setUnAllocated([...unAllocated, entityId]);
    }
  };
  const allocateEntities = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let requests = [];
    if (allocated.length) {
      requests.push(axios.put(`/admin/users/${id}/allocate`, { entityIds: allocated }, { headers }));
    }
    if (unAllocated.length) {
      requests.push(axios.delete(`/admin/users/${id}/allocate`, { data: { entityIds: unAllocated }, headers }));
    }
    try {
      const responses = await Promise.all(requests);
      if (responses.map((r) => r.status).every((s) => s === 200)) {
        setPopup({ shown: true });
      }
    } catch (e) {
      throwError(e);
    }
  };
  const initEntityList = () => {
    setEntityList(
      entities.map((e) => ({
        ...e,
        allocate: e.adminUsers.map((u) => u.email).includes(user.email),
        pendingAllocation: e.adminUsers.map((u) => u.email).includes(user.email),
        data: e.data && e.data.name ? e.data : { ...e.data, name: I18n.t('general/draft') },
      }))
    );
  };
  const resetAllocation = () => {
    setEntityList(
      entityList.map((e) => ({
        ...e,
        pendingAllocation: e.allocate,
      }))
    );
    setAllocated([]);
    setUnAllocated([]);
  };
  const tableHeaderColumns =
    clientConfig &&
    (userData && userData.role === 'admin'
      ? ['allocate', ...clientConfig.tableHeaders.entityDetails.admin]
      : ['allocate', ...clientConfig.tableHeaders.entityDetails.client]);
  const checkboxClicked = (e, entity) => {
    e.stopPropagation();
    toggleAllocated(entity);
    setEntityList(
      entityList.map((e) => ({
        ...e,
        pendingAllocation: e === entity ? !e.pendingAllocation : e.pendingAllocation,
      }))
    );
  };
  const allocateColumn = (entity) => (
    <div className="checkbox">
      <label className="checkbox-line">
        <input
          type="checkbox"
          checked={entity.allocate === entity.pendingAllocation ? entity.allocate : entity.pendingAllocation}
          onChange={(e) => checkboxClicked(e, entity)}
        />
        <span className={`checkmark ${entity.allocate !== entity.pendingAllocation ? 'not-saved' : ''}`}></span>
      </label>
    </div>
  );
  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredEntityList.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        });
      },
    },
    switchers: [
      {
        text: 'entity-details/only-with-open-submissions',
        checked: searchAndFilter.activeProcess,
        onSwitcherChange: () => {
          setSearchAndFilter({
            ...searchAndFilter,
            activeProcess: event.target.checked,
          });
        },
      },
    ],
    selects: [
      ...(Object.values(modules).filter((m) => m.autoCreateEntity).length > 1
        ? [
            {
              name: 'module',
              className: 'module-selector',
              selected: searchAndFilter.module,
              onSelectChange: (selectValue) =>
                setSearchAndFilter({
                  ...searchAndFilter,
                  module: selectValue,
                }),
              options: Object.values(modules)
                .filter((m) => m.autoCreateEntity)
                .map((m) => ({ value: m._id, label: translate(m.label) })),
            },
          ]
        : []),
      {
        name: 'state',
        className: 'state-selector',
        selected: searchAndFilter.state,
        onSelectChange: (selectValue) =>
          setSearchAndFilter({
            ...searchAndFilter,
            state: selectValue,
          }),
        options: [
          {
            value: 'completed',
            label: I18n.t('general/completed'),
          },
          {
            value: 'pending',
            label: I18n.t('general/pending'),
          },
          {
            value: 'incomplete',
            label: I18n.t('general/incomplete'),
          },
        ],
      },
    ],
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };

  return (
    <>
      <SubHeader>
        <div className="column">
          <div className="title">
            <TextWithTooltip textWithOverflow={user.name} />
          </div>
        </div>
        <div className="column">
          <ViewOptions options={['toggleFilterBar']} />
          <button
            className="subheader-button"
            disabled={!allocated.length && !unAllocated.length}
            onClick={resetAllocation}
          >
            <Svg src={resetIcon} otherColor="#FFFFFF" />
            <Translate value="delegation/reset" />
          </button>
          <button
            className="subheader-button"
            disabled={!allocated.length && !unAllocated.length}
            onClick={allocateEntities}
          >
            <Svg src={saveIcon} otherColor="#FFFFFF" />
            <Translate value="form/save" />
          </button>
        </div>
      </SubHeader>
      <Filterbar {...filterbarProps} />
      <div className="allocated-entities-container">
        <EntityTable
          entities={filteredEntityList}
          setEntities={setFilteredEntityList}
          tableHeaderColumns={tableHeaderColumns}
          goToEntity={goToEntity}
          showStatusIndicator={false}
          extraContent={allocateColumn}
        />
      </div>
      <Popup
        popupShown={popup.shown}
        okHandler={() => {
          setPopup({ shown: false });
          fetchEntities();
          setAllocated([]);
          setUnAllocated([]);
        }}
        popupTitle={I18n.t('user-management/admins/allocate')}
      ></Popup>
    </>
  );
};

function mapStateToProps(state) {
  const { auth, entity, modules } = state;
  return {
    token: auth.token,
    entities: entity.entities,
    isLoaded: entity.isLoaded,
    authenticated: auth.authenticated,
    userData: auth.userData,
    modules: modules.modules,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchEntities: entityThunks.fetchEntities,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(AllocatedEntities), [
  'list-all-entities',
  'delegate-user',
]);
