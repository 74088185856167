import React from 'react';
import { I18n } from 'react-redux-i18n';

import StatusIndicator from '../StatusIndicator';
import TextWithTooltip from '../TextWithTooltip';

import {
  translate,
  beautifyLongTextArray,
  getDateInLocalFormat,
  getSortFunctionWithDirection,
} from '../../helper/functions';

import './ResponsiveTileView.scss';

import entity_icon from '../../assets/entity_icon.svg';
import user_icon from '../../assets/grey_user.svg';

const ResponsiveTileView = (props) => {
  const { tableHeaderColumns, tileContent, onTileClick = () => {}, defaultSort, type } = props;
  const classOfTiles = tileContent.length > 3 ? 'grid-container' : 'flex-container';
  const tileIcon = type === 'user' || type === 'admin' ? user_icon : entity_icon;

  const sortedContent = [...tileContent].sort(getSortFunctionWithDirection(defaultSort, true));

  const renderKeyValue = (headerColumn, content, index) => {
    let value = null;
    switch (headerColumn) {
      case 'updated':
      case 'created':
      case 'lastSubmissionUpdate':
      case 'lastLogin':
        value = content[headerColumn] ? getDateInLocalFormat(content[headerColumn]) : '-';
        break;
      case 'activeSubmission':
      case 'allSubmission':
      case 'allocatedEntityCount':
        value = content[headerColumn];
        break;
      case 'role':
      case 'claim':
        value = content.profile[headerColumn];
        break;
      case 'state':
        value = <StatusIndicator state={content[headerColumn]} />;
        break;
      case 'creatorModule':
        value = <TextWithTooltip textWithOverflow={translate(content[headerColumn].label)} />;
        break;
      case 'users':
        value = (
          <TextWithTooltip
            textWithOverflow={content[headerColumn].length === 0 ? '-' : content[headerColumn][0].profile.name}
          />
        );
        break;
      case 'email':
        value = <TextWithTooltip textWithOverflow={content[headerColumn]} />;
        break;
      case 'adminUsers':
        value = (
          <TextWithTooltip
            textWithOverflow={beautifyLongTextArray(
              content[headerColumn].map((user) => user.profile.name),
              1,
              'and',
              'others'
            )}
            tooltipContent={content[headerColumn].map((user) => user.profile.name).join(', ')}
            showTooltipIfTextNotOverflow={content[headerColumn].length > 1}
          />
        );
        break;
      default:
        value = null;
    }

    if (headerColumn === 'name' || headerColumn === 'clientName' || headerColumn === 'label') return null;
    return (
      <div key={index} className="tile-content-row">
        <span className="content-key">{I18n.t(type + '-list-table-header/' + headerColumn)}</span>
        <span className={'content-value ' + headerColumn}>{value}</span>
      </div>
    );
  };

  return (
    <div className={'tile-container hide-scrollbar ' + classOfTiles}>
      {sortedContent.map((content, i) => {
        let contentTitle = '';
        if (type === 'user' || type === 'admin') {
          contentTitle = content.profile.name;
        }
        if (type === 'entity') {
          contentTitle = content.data.name;
        }
        if (type === 'group') {
          contentTitle = translate(content.label);
        }
        return (
          <div className="tile" key={i} onClick={() => onTileClick(content)}>
            <div className="tile-header">
              <div className="img-container">
                <img src={tileIcon} />
              </div>
              <div className="tile-title">
                <TextWithTooltip textWithOverflow={contentTitle} textClassName="title" />
              </div>
            </div>
            <div className="tile-content">
              {tableHeaderColumns.map((tc, index) => {
                return renderKeyValue(tc, content, index);
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ResponsiveTileView;
