import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadTranslations, setLocale } from 'react-redux-i18n';
import axios from 'axios';
import { setDefaultLocale, registerLocale } from 'react-datepicker';
import { Helmet } from 'react-helmet';
import hu from 'date-fns/locale/hu';
import en from 'date-fns/locale/en-US';

import * as authActions from '../actions/auth';
import { generateTheme } from '../helper/client-config';
import { getLocaleConfig } from '../helper/functions';

import '../variables.scss';
import '../core.scss';

const datepickerLangs = {
  hu_HU: { translation: hu, code: 'hu' },
  en_US: { translation: en, code: 'en' },
};

class Layout extends Component {
  constructor(props) {
    super(props);
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          if (error.response.data.error === 'INVALID_TOKEN' && props.history.location.pathname !== '/reset-password') {
            if (props.history.location.pathname !== '/login') {
              localStorage.setItem('navigateAfterLogin', props.history.location.pathname);
            }
            props.logout();
            props.history.push('/login');
          } else {
            props.history.push('/');
          }
        }
        return Promise.reject(error);
      }
    );

    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    Promise.all([axios.get('/client'), axios.get('/system/translations')])
      .then((responses) => {
        global.clientConfig = responses[0].data.config || {};
        this.props.loadTranslations(responses[1].data);
        this.props.setLocale(getLocaleConfig());
        this.setState({ initialized: true });
        generateTheme();
        Object.values(datepickerLangs).forEach((lang) => registerLocale(lang.code, lang.translation));
        setDefaultLocale(datepickerLangs[getLocaleConfig()].code);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.props.setLocale(getLocaleConfig());
      setDefaultLocale(datepickerLangs[getLocaleConfig()].code);
    }
  }

  render() {
    if (!this.state.initialized) {
      return null;
    }

    return (
      <div className="layout">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{clientConfig.site && clientConfig.site.title}</title>
          <meta
            name="description"
            content={clientConfig.site && clientConfig.site.meta && clientConfig.site.meta.description}
          />
          <meta
            name="keywords"
            content={clientConfig.site && clientConfig.site.meta && clientConfig.site.meta.keywords}
          />
        </Helmet>
        {this.props.children}
      </div>
    );
  }
}

Layout.propTypes = {
  logout: PropTypes.func,
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.auth.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: authActions.logout,
      loadTranslations,
      setLocale,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
