import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';

import Svg from '../Svg';
import WrappedLink from '../WrappedLink';

import { postResults } from '../../thunks/postresults';
import { calculateRoutePath } from '../../helper/navigation';

import prev from '../../assets/blue_prev.svg';
import next from '../../assets/blue_next.svg';

class NavigationButtons extends React.Component {
  constructor(props) {
    super(props);
  }
  handleSubmit = (e, nextStageId, complete = false) => {
    e.preventDefault();
    const { stageId, submission, params, token, stageResults, userData, history } = this.props;
    const submissionId = params.submissionId;
    const usersMainRoute = userData && userData.mainRoute;

    this.props.postResults(submissionId, stageId, token, stageResults, () => {
      const routeToPush = complete
        ? submission.entityId
          ? `/client-management/entity/${submission.entityId}`
          : '/client-management'
        : `/client-management/${params.submissionId}/${nextStageId}`;
      history.push(calculateRoutePath(usersMainRoute, routeToPush));
    });
  };

  render() {
    const { filteredStageMeta, stage, submission, stageResults, params, module } = this.props;
    const { isBlocked, completed } = submission;

    if (!submission || !filteredStageMeta.length) {
      return null;
    }

    const currentPosition = filteredStageMeta.findIndex((sm) => sm.stageId === stage._id);
    const blockedPosition = filteredStageMeta.findIndex((sm) => sm.stageId === isBlocked);
    const prevStageId = currentPosition > 0 ? filteredStageMeta[currentPosition - 1].stageId : null;
    const nextStageId =
      currentPosition + 1 < filteredStageMeta.length ? filteredStageMeta[currentPosition + 1].stageId : null;
    const notBlocked = !isBlocked || currentPosition < blockedPosition;

    const prevLink = `/${params.submissionId}/${prevStageId}`;
    const formValid =
      stageResults.length > 0 &&
      !stageResults.find((stageResult) => {
        return stageResult.values.find((value) => {
          return value.error;
        });
      });

    return (
      <div className="prev-next-container form-style">
        <div className="button-container">
          {prevStageId && !completed ? (
            <WrappedLink to={prevLink}>
              <button className="prev">
                <Svg src={prev} otherColor="#FFF" />
                <span>{I18n.t('general/previous')}</span>
              </button>
            </WrappedLink>
          ) : (
            <button className="prev inactive">
              <Svg src={prev} otherColor="#FFF" />
              <span>{I18n.t('general/previous')}</span>
            </button>
          )}
          {nextStageId || module.autoCompleteSubmission ? (
            (formValid || stage.type !== 'data-input') && notBlocked && !completed && !submission.loading ? (
              <button
                onClick={(e) => this.handleSubmit(e, nextStageId, !nextStageId && module.autoCompleteSubmission)}
                className="next"
              >
                <span>
                  {I18n.t(
                    !nextStageId && module.autoCompleteSubmission
                      ? 'general/complete-submission'
                      : 'general/save-and-next'
                  )}
                </span>
                <Svg src={next} otherColor="#FFF" />
              </button>
            ) : (
              <button className="next inactive">
                <span>{I18n.t('general/save-and-next')}</span>
                <Svg src={next} otherColor="#FFF" />
              </button>
            )
          ) : null}
        </div>
      </div>
    );
  }
}

NavigationButtons.propTypes = {
  authenticated: PropTypes.bool,
  token: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const stage = state.modules.stages[ownProps.params.stageId];
  const stageGroups = stage.groupMeta.map((gm) => gm.groupId);
  const stageResults =
    ownProps.submission && ownProps.submission.data
      ? ownProps.submission.data.filter((s) => stageGroups.includes(s.groupId))
      : [];
  const stageId = ownProps.params.stageId;
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    entities: state.entity.entities,
    stages: state.modules.stages,
    userData: state.auth.userData,
    module: ownProps.submission ? state.modules.modules[ownProps.submission.moduleId] : null,
    stage,
    stageId,
    stageResults,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postResults,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationButtons);
