import { types } from '../actions/submissions';
import { types as authTypes } from '../actions/auth';
import { fieldValidator } from '../helper/functions';

export default function (state = {}, action) {
  switch (action.type) {
    case types.SET_SUBMISSIONS:
      const submissions = {};
      action.data.forEach((s) => (submissions[s.submissionId] = { ...s }));
      return {
        ...state,
        ...submissions,
      };
    case types.SET_SUBMISSION_DATA:
      return {
        ...state,
        [action.data._id]: {
          ...state[action.data._id],
          ...action.data,
          loading: false,
        },
      };
    case types.SET_SUBMISSION_LOADING:
      return {
        ...state,
        [action.data.submissionId]: {
          ...state[action.data.submissionId],
          loading: action.data.loading,
        },
      };
    case types.CHANGE_VALUE:
      const submission = { ...state[action.data.submissionId] };
      const groupIndex = submission.data.findIndex(
        (d) => d.groupId === action.data.groupId && d.position === action.data.groupPosition
      );
      let newData = null;
      const newFieldValueObject = {
        fieldId: action.data.fieldId,
        value: action.data.value,
        error: action.data.error,
      };
      if (action.data.resultFieldId) {
        newFieldValueObject.resultFieldId = action.data.resultFieldId;
      }
      if (action.data.referenceResultId) {
        newFieldValueObject.readonly = true;
      }
      if (groupIndex === -1) {
        // first value set in group
        newData = [
          ...submission.data,
          {
            groupId: action.data.groupId,
            position: action.data.groupPosition,
            values: [newFieldValueObject],
          },
        ];
        if (action.data.referenceResultId) {
          newData[newData.length - 1].referenceResultId = action.data.referenceResultId;
        }
      } else {
        // change existing group results value
        newData = [
          ...submission.data.slice(0, groupIndex),
          {
            ...submission.data[groupIndex],
            referenceResultId: action.data.referenceResultId
              ? action.data.referenceResultId
              : submission.data[groupIndex].referenceResultId,
            values: submission.data[groupIndex].values.find((v) => v.fieldId == action.data.fieldId)
              ? // change existing fieldvalue
                submission.data[groupIndex].values.map((v) => {
                  if (v.fieldId !== action.data.fieldId) return v;
                  return {
                    ...v,
                    ...newFieldValueObject,
                  };
                })
              : // add value for new field
                [...submission.data[groupIndex].values, newFieldValueObject],
          },
          ...submission.data.slice(groupIndex + 1),
        ];
      }
      return {
        ...state,
        [action.data.submissionId]: {
          ...submission,
          data: newData,
        },
      };
    case types.ADD_NEW_GROUP_RESULT:
      return {
        ...state,
        [action.data.submissionId]: {
          ...state[action.data.submissionId],
          data: [
            ...state[action.data.submissionId].data,
            {
              groupId: action.data.groupId,
              position: action.data.position,
              values: [],
            },
          ],
        },
      };
    case types.DELETE_GROUP_RESULT:
      if (action.shouldDeleteWholeGroupResult) {
        return {
          ...state,
          [action.data.submissionId]: {
            ...state[action.data.submissionId],
            data: state[action.data.submissionId].data
              .filter((resultData) => {
                return resultData.groupId !== action.data.groupId || resultData.position !== action.data.position;
              })
              .map((resultData) => {
                if (resultData.groupId === action.data.groupId && resultData.position > action.data.position) {
                  return {
                    ...resultData,
                    position: resultData.position - 1,
                  };
                }
                return resultData;
              }),
          },
        };
      } else {
        const data = state[action.data.submissionId].data.map((resultData) => {
          if (resultData.groupId === action.data.groupId) {
            return {
              ...resultData,
              referenceResultId: undefined,
              resultId: undefined,
              values: resultData.values.map((d) => {
                const field = action.fields[d.fieldId];
                const error = fieldValidator(
                  null,
                  field.validationRules,
                  field.type,
                  action.submission,
                  resultData.groupId
                );

                return { ...d, value: null, error, readonly: false, resultFieldId: undefined };
              }),
            };
          }

          return resultData;
        });

        return {
          ...state,
          [action.data.submissionId]: {
            ...state[action.data.submissionId],
            data,
          },
        };
      }
    case types.SET_STAGE_RESULTS:
      return {
        ...state,
        [action.data.submissionId]: {
          ...state[action.data.submissionId],
          data: state[action.data.submissionId].data.map((d) => {
            const newGroupResult = action.data.groupResults.find(
              (gr) => gr.groupId === d.groupId && gr.position === d.position
            );
            if (newGroupResult) {
              return newGroupResult;
            } else {
              return d;
            }
          }),
          stageHistory: action.data.stageId
            ? state[action.data.submissionId].stageHistory.includes(action.data.stageId)
              ? state[action.data.submissionId].stageHistory
              : [...state[action.data.submissionId].stageHistory, action.data.stageId]
            : state[action.data.submissionId].stageHistory,
        },
      };
    case types.SET_SUBMISSION_SETTINGS:
      return {
        ...state,
        [action.data.submissionId]: {
          ...state[action.data.submissionId],
          data: state[action.data.submissionId].data.map((groupResult) => {
            if (groupResult.resultId !== action.data.resultId) {
              return groupResult;
            }
            return {
              ...groupResult,
              [action.data.key]: action.data.groupSettings[action.data.key],
              values: groupResult.values.map((v) => {
                const newFieldSetting = action.data.groupSettings.fields.find(
                  (gsField) => gsField.resultFieldId === v.resultFieldId
                );
                if (newFieldSetting) {
                  return {
                    ...v,
                    [action.data.key]: newFieldSetting[action.data.key],
                  };
                }
                return v;
              }),
            };
          }),
        },
      };
    case authTypes.LOGOUT:
      return {};
    case types.SET_SUBMISSION_OVERRIDE:
      return {
        ...state,
        [action.data.submissionId]: {
          ...state[action.data.submissionId],
          isBlocked: action.data.isBlocked,
        },
      };
    case types.SET_DOCUMENTS:
      return {
        ...state,
        [action.data.submissionId]: {
          ...state[action.data.submissionId],
          documents: action.data.documents,
        },
      };
    default:
      return state;
  }
}
