import React from 'react';

import './InputWithError.scss';

import error_icon from '../../assets/error_icon.svg';

export default function InputWithError(props) {
  const { errorMsg, placeholder, autoFilled, ...inputProps } = props;
  return (
    <div className="input-with-error">
      <div className="input-with-error-wrapper">
        <input
          className={(errorMsg ? ' error' : '') + (props.value || props.autoFilled ? ' has-value' : '')}
          {...inputProps}
        />
        <div className="active-placeholder">
          <span>{placeholder}</span>
        </div>
        {errorMsg && <img className="error-icon" src={error_icon} />}
      </div>
      <div className="error-message">{errorMsg}</div>
    </div>
  );
}

InputWithError.defaultProps = {
  value: '',
};
