import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import StatusIndicator from '../StatusIndicator';
import Svg from '../Svg';
import Comment from '../Comment';

import { translate, getNiceValueFormat } from '../../helper/functions';
import { calculateRoutePath } from '../../helper/navigation';

import './GroupResult.scss';

import edit from '../../assets/pencil_icon.svg';
import arrow from '../../assets/accordion_arrow.svg';

const GroupResult = ({
  fields,
  groups,
  result,
  search = '',
  match,
  stageId = null,
  isSubmissionCompleted,
  userData,
  history,
}) => {
  if (result && result.hidden) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(result.isOpen);
  }, [result.isOpen]);

  const openGroup = () => setIsOpen(!isOpen);

  const usersMainRoute = userData && userData.mainRoute;
  const group = groups[result.groupId];
  const isEditingDisabled = result.state === 'approved' || isSubmissionCompleted;
  const filteredFieldMeta =
    search && !translate(group.label).toLowerCase().includes(search)
      ? group.fieldMeta.filter(({ fieldId }) => {
          const field = fields[fieldId];
          const fieldResult = result.values.find((resultValue) => resultValue.fieldId === fieldId) || {};
          const lowerCaseSearch = search.toLowerCase();
          const actualLabel =
            !field.label || translate(field.label).length === 0 ? translate(field.placeholder) : translate(field.label);
          return (
            (fieldResult.value &&
              getNiceValueFormat(field, fieldResult.value).toString().toLowerCase().includes(lowerCaseSearch)) ||
            actualLabel.toLowerCase().includes(lowerCaseSearch)
          );
        })
      : group.fieldMeta;

  if (filteredFieldMeta.length === 0) {
    return null;
  }

  return (
    <div className="data-section-container ">
      <div className="group-header">
        <div className={'group-header-title ' + result.state}>
          <span>{translate(group.label)}</span>
        </div>
        <div className="group-header-status-container">
          <StatusIndicator state={result.state} />
        </div>
        {result && result.comment && result.comment.comment && (
          <div className="img-container message">
            <Comment comment={result.comment} targetName={translate(group.label)} alternateColor={true} />
          </div>
        )}
        {stageId && (
          <div
            className={`img-container ${isEditingDisabled ? 'disabled' : ''}`}
            onClick={() => {
              if (!isEditingDisabled) {
                history.push(
                  calculateRoutePath(
                    usersMainRoute,
                    `/client-management/${match.params.submissionId}/${stageId}/${result.groupId}${result.position}`
                  )
                );
              }
            }}
          >
            <Svg src={edit} hasHover={!isEditingDisabled} />
          </div>
        )}
        <div className={`img-container arrow ${isOpen ? 'opened' : ''}`} onClick={openGroup}>
          <Svg src={arrow} hasHover={true} />
        </div>
      </div>
      <div className={`content-rows ${isOpen ? 'opened' : 'closed'}`} key={result._id}>
        {filteredFieldMeta.map(({ fieldId }, i) => {
          const field = fields[fieldId];
          if (field.type === 'separator') return null;
          const value = result.values.find((v) => v.fieldId === fieldId) || {};
          const fieldHidden = 'hidden' in value ? value.hidden : false;
          if (fieldHidden) return null;
          const fieldState = value.state || result.state;
          return (
            <div className="row" key={i}>
              <div className="cell label">
                {!field.label || translate(field.label).length === 0
                  ? translate(field.placeholder)
                  : translate(field.label)}
                :
              </div>
              <div className="cell wider">
                <span>{getNiceValueFormat(field, value.value)}</span>
              </div>
              <div className={'cell status ' + fieldState}></div>
              {result && result.comment && result.comment.comment && <div className="cell placeholder"></div>}
              {stageId && <div className="cell placeholder"></div>}
              <div className="cell placeholder"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

GroupResult.propTypes = {
  token: PropTypes.string,
  groups: PropTypes.object,
  fields: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    token: state.auth.token,
    groups: state.modules.groups,
    fields: state.modules.fields,
    userData: state.auth.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupResult));
