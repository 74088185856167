export const types = {
  SET_MODULES: 'SET_MODULES',
  SET_MODULE_DATA: 'SET_MODULE_DATA'
}

export function setModules(data) {
  return {
    type: types.SET_MODULES,
    data
  }
}

export function setModuleData(data) {
  return {
    type: types.SET_MODULE_DATA,
    data
  }
}
