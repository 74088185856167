export const types = {
  SET_SUBMISSIONS: 'SET_SUBMISSIONS',
  SET_SUBMISSION_DATA: 'SET_SUBMISSION_DATA',
  SET_SUBMISSION_LOADING: 'SET_SUBMISSION_LOADING',
  CHANGE_VALUE: 'CHANGE_VALUE',
  SET_STAGE_RESULTS: 'SET_STAGE_RESULTS',
  ADD_NEW_GROUP_RESULT: 'ADD_NEW_GROUP_RESULT',
  DELETE_GROUP_RESULT: 'DELETE_GROUP_RESULT',
  SET_SUBMISSION_SETTINGS: 'SET_SUBMISSION_SETTINGS',
  SET_SUBMISSION_OVERRIDE: 'SET_SUBMISSION_OVERRIDE',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
};

export function setSubmissions(data) {
  return {
    type: types.SET_SUBMISSIONS,
    data,
  };
}

export function setSubmissionData(data) {
  return {
    type: types.SET_SUBMISSION_DATA,
    data,
  };
}

export function setSubmissionLoading(submissionId, loading) {
  return {
    type: types.SET_SUBMISSION_LOADING,
    data: {
      submissionId,
      loading,
    },
  };
}

export function setStageResults(submissionId, groupResults, stageId) {
  return {
    type: types.SET_STAGE_RESULTS,
    data: {
      submissionId,
      groupResults,
      stageId,
    },
  };
}

export function changeValue({
  submissionId,
  groupId,
  groupPosition,
  fieldId,
  value,
  error,
  referenceResultId,
  resultFieldId,
}) {
  return {
    type: types.CHANGE_VALUE,
    data: {
      submissionId,
      groupId,
      groupPosition,
      fieldId,
      value,
      error,
      referenceResultId,
      resultFieldId,
    },
  };
}

export function addNewGroupResult(submissionId, groupId, position) {
  return {
    type: types.ADD_NEW_GROUP_RESULT,
    data: {
      submissionId,
      groupId,
      position,
    },
  };
}

export function deleteGroupResult(submissionId, groupId, position, shouldDeleteWholeGroupResult, fields, submission) {
  return {
    type: types.DELETE_GROUP_RESULT,
    data: {
      submissionId,
      groupId,
      position,
    },
    shouldDeleteWholeGroupResult,
    fields,
    submission,
  };
}

export function setSubmissionSettings(submissionId, resultId, key, groupSettings = {}) {
  return {
    type: types.SET_SUBMISSION_SETTINGS,
    data: {
      submissionId,
      resultId,
      key,
      groupSettings,
    },
  };
}

export function setSubmissionOverride(submissionId, isBlocked) {
  return {
    type: types.SET_SUBMISSION_OVERRIDE,
    data: {
      submissionId,
      isBlocked,
    },
  };
}

export function setDocuments(submissionId, documents) {
  return {
    type: types.SET_DOCUMENTS,
    data: {
      submissionId,
      documents,
    },
  };
}
