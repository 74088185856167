import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import TextWithTooltip from '../TextWithTooltip';
import ResponsiveTable from '../ResponsiveTable';
import ResponsiveTileView from '../ResponsiveTileView';

import { calculateRoutePath } from '../../helper/navigation';
import { getDateInLocalFormat } from '../../helper/functions';

import './AdminsTable.scss';

const AdminsTable = (props) => {
  const { history, filteredAdmins, extraHeaders, userData, extraContent, viewMode, roles, componentName } = props;
  const filteredAdminsWithRightRole = roles.fetched
    ? filteredAdmins.map((fa) => {
        return {
          ...fa,
          profile: {
            ...fa.profile,
            role: roles.data.find((r) => r.value === fa.profile.role).label,
          },
        };
      })
    : [];

  const usersMainRoute = userData && userData.mainRoute;

  let headerColumns = [
    {
      name: I18n.t('users-table/table-header-1'),
      class: `col-4 ${extraHeaders ? 'sticky' : ''}`,
      sortName: 'clientName',
    },
    { name: I18n.t('users-table/table-header-2'), class: 'col-3', sortName: 'email' },
    { name: I18n.t('users-table/table-header-5'), class: 'col-2', sortName: 'role' },
    { name: I18n.t('users-table/table-header-6'), class: '', sortName: 'claim' },
    { name: I18n.t('users-table/table-header-3'), class: '', sortName: 'created' },
    { name: I18n.t('users-table/table-header-4'), class: '', sortName: 'lastLogin' },
    { name: I18n.t('users-table/table-header-7'), class: 'half', sortName: 'allocatedEntityCount' },
  ];

  const goToAdmin = (user) => {
    history.push(calculateRoutePath(usersMainRoute, `/user-management/admin/${user._id}/edit-rights-and-data`));
  };

  if (viewMode[componentName] === 'tile') {
    return (
      <ResponsiveTileView
        type="user"
        tileContent={filteredAdminsWithRightRole}
        tableHeaderColumns={headerColumns.map((hc) => hc.sortName)}
        onTileClick={goToAdmin}
        defaultSort="clientName"
      />
    );
  }

  return (
    <ResponsiveTable
      headerColumns={extraHeaders ? extraHeaders.concat(headerColumns) : headerColumns}
      defaultSort={extraHeaders ? extraHeaders[0].sortName : 'clientName'}
      isAscending={extraHeaders ? false : true}
      data={filteredAdminsWithRightRole}
      renderRow={(user, options) => (
        <div
          key={user._id}
          className="table-row admin"
          onClick={() =>
            history.push(calculateRoutePath(usersMainRoute, `/user-management/admin/${user._id}/edit-rights-and-data`))
          }
        >
          {extraContent ? extraContent(user) : null}
          <div
            className="column col-4 main-color align-left bigger-padding sticky"
            style={extraHeaders && options.stickyPosition ? { left: options.stickyPosition } : null}
          >
            <TextWithTooltip textWithOverflow={user.profile.name} />
          </div>
          <div className="column col-3 main-color bigger-padding">
            <TextWithTooltip textWithOverflow={user.email} />
          </div>
          <div className="column col-2">{user.profile.role}</div>
          <div className="column">{user.profile.claim}</div>
          <div className="column">{getDateInLocalFormat(user.created)}</div>
          <div className="column">{user.lastLogin ? getDateInLocalFormat(user.lastLogin) : '-'}</div>
          <div className="column half">{user.allocatedEntityCount || '-'}</div>
        </div>
      )}
    ></ResponsiveTable>
  );
};

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
    viewMode: state.layout.viewMode,
  };
}

export default connect(mapStateToProps, null)(AdminsTable);
