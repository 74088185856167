import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';

const AsyncButton = ({ children, asyncMethod }) => {
  const mounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const memoizedAsyncMethod = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    asyncMethod().finally(() => {
      if (mounted.current) {
        setIsLoading(false);
      }
    });
  }, [asyncMethod, isLoading]);
  useEffect(() => {
    return () => (mounted.current = false);
  }, []);

  return (
    <button className={isLoading ? 'inactive' : ''} onClick={memoizedAsyncMethod}>
      {children}
    </button>
  );
};

AsyncButton.propTypes = {
  asyncMethod: PropTypes.func,
};

export default AsyncButton;
