import React, { useState } from 'react';
import { Translate, I18n } from 'react-redux-i18n';

import Svg from '../Svg';

import searchIcon from '../../assets/search_black.svg';

import './Searchbar.scss';

const SearchBar = (props) => {
  const { resultsLength, type, placeholder, onChange } = props;
  const [onMouse, setMouseIsOn] = useState(false);
  const [focused, setFocused] = useState(false);
  const resultText =
    resultsLength === undefined ? '' : resultsLength < 2 ? 'entity-details/result' : 'entity-details/results';

  return (
    <div
      className={'header-block searchbar-container ' + (onMouse || focused ? ' active' : '')}
      onMouseOver={() => setMouseIsOn(true)}
      onMouseOut={() => setMouseIsOn(false)}
    >
      <input
        type={type}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className="searchbar"
        placeholder={I18n.t(placeholder)}
        onChange={onChange}
      ></input>
      <Translate
        value={resultText}
        count={resultsLength}
        className={'result-text ' + (onMouse || focused ? ' active' : '')}
      />
      <Svg
        src={searchIcon}
        otherColor={onMouse || focused ? clientConfig.colors.svgHoverColor : null}
        className="searchbar-svg"
      />
    </div>
  );
};

export default SearchBar;
