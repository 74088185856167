import axios from 'axios';
import * as submissionActions from '../actions/submissions';

export function postResults(submissionId, stageId, token, stageResults = [], callback = null) {
  const stageResultsWithOutNullValues = stageResults
    .map((stageResult) => {
      return {
        ...stageResult,
        values: stageResult.values.filter((value) => {
          return value.value || value.value === false;
        }),
      };
    })
    .filter((stageResult) => stageResult.values.length > 0);

  return (dispatch) => {
    dispatch(submissionActions.setSubmissionLoading(submissionId, true));
    axios
      .put(
        '/drf/submissions/' + submissionId,
        {
          stageId,
          results: stageResultsWithOutNullValues,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        dispatch(submissionActions.setStageResults(submissionId, response.data, stageId));
        dispatch(submissionActions.setSubmissionLoading(submissionId, false));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (callback) callback();
      });
  };
}
