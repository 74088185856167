import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';

import './StatusIndicator.scss';

const StatusIndicator = ({ state }) => (
  <div className={`status-container ${state}`}>
    <div className="status-indicator"></div>
    <Translate value={`general/${state}`} />
  </div>
);

StatusIndicator.propTypes = {
  state: PropTypes.string,
};

export default StatusIndicator;
