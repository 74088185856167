import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AuthComponentWrapper from '../AuthComponentWrapper';
import BackButton from '../BackButton';
import ScrollToTop from '../ScrollToTop';
import DeleteButton from '../DeleteButton';
import WrappedLink from '../WrappedLink';
import TextWithTooltip from '../TextWithTooltip';

import pwd_by_consolidity_logo from '../../assets/pwd_by_consolidity.svg';

import { calculateRoutePath } from '../../helper/navigation';

const SideBarLayout = ({ sideBarElements, dataArrived, userData, hideBackButton = false }) => {
  const { navigation, backButton, deleteButton, redirectRoute } = sideBarElements;
  const usersMainRoute = userData && userData.mainRoute;

  return (
    <>
      <div className="sidebar">
        <BackButton title={backButton.title} backUrl={backButton.url} className={hideBackButton ? 'invisible' : null} />
        <div className="navigate-panel entity-details">
          {navigation.map((link, index) => (
            <AuthComponentWrapper allowedRules={link.rules} key={index}>
              <WrappedLink to={link.destination} className={link.class} navLink={true}>
                <TextWithTooltip textWithOverflow={link.name} />
              </WrappedLink>
            </AuthComponentWrapper>
          ))}
        </div>
        <div className="sidebar-bottom">
          <DeleteButton {...deleteButton} />
          <div className="pwd-by-consolidity">
            {clientConfig && clientConfig.poweredBy && <img src={pwd_by_consolidity_logo}></img>}
          </div>
        </div>
      </div>
      <div className="subpage-container">
        {dataArrived && (
          <ScrollToTop>
            <Switch>
              {navigation.map((link, index) => (
                <Route
                  path={calculateRoutePath(usersMainRoute, link.destination)}
                  render={link.component}
                  key={index}
                />
              ))}
              <Redirect from={``} to={redirectRoute} />
            </Switch>
          </ScrollToTop>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
  };
}

export default connect(mapStateToProps, null)(SideBarLayout);
