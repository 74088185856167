import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import axios from 'axios';

import Svg from '../Svg/Svg';
import Comment from '../Comment';
import Group from '../Group';
import StatusSelector from '../StatusSelector';

import { postResults } from '../../thunks/postresults';
import * as submissionActions from '../../actions/submissions';
import * as errorActions from '../../actions/error';

import { translate, getNiceValueFormat } from '../../helper/functions';

import './AdminGroupResult.scss';

import edit from '../../assets/edit.svg';
import eye from '../../assets/eye.svg';
import eye_no from '../../assets/eye_no.svg';
import cancel from '../../assets/cancel.svg';
import save from '../../assets/save.svg';

const settingsOptionValues = {
  state: ['approved', 'pending', 'rejected'],
};

class AdminGroupResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
  }

  searchMatch = (fieldResult) => {
    const field = this.props.fields[fieldResult.fieldId];
    const lowerCaseSearch = this.props.search.toLowerCase();
    const actualLabel =
      !field.label || translate(field.label).length === 0 ? translate(field.placeholder) : translate(field.label);
    return (
      (fieldResult.value &&
        getNiceValueFormat(field, fieldResult.value).toString().toLowerCase().includes(lowerCaseSearch)) ||
      actualLabel.toLowerCase().includes(lowerCaseSearch)
    );
  };

  handleSelectChange = (event, result, resultFieldId) => {
    const { value } = event;
    this.setFieldSettings('state', value, result, resultFieldId);
  };

  setFieldSettings = (key, value, result, resultFieldId) => {
    const { submissionId, token } = this.props;
    const groupSettings = {
      resultGroupId: result.resultId,
      fields: [],
    };
    if (!resultFieldId) {
      groupSettings[key] = value;
      groupSettings.fields = result.values.map((fieldValue) => {
        return {
          resultFieldId: fieldValue.resultFieldId,
          [key]: value,
        };
      });
    } else {
      if (key === 'state') {
        let lowestSettingValueIndex = 0;
        result.values.forEach((fieldValue) => {
          const fieldSettingValue = resultFieldId === fieldValue.resultFieldId ? value : fieldValue[key];
          const fieldSettingValueIndex = settingsOptionValues[key].findIndex(
            (optionValue) => optionValue === fieldSettingValue
          );
          if (fieldSettingValueIndex > lowestSettingValueIndex) {
            lowestSettingValueIndex = fieldSettingValueIndex;
          }
        });
        groupSettings[key] = settingsOptionValues[key][lowestSettingValueIndex];
        groupSettings.fields = [
          {
            resultFieldId,
            [key]: value,
          },
        ];
      } else {
        groupSettings.fields = [
          {
            resultFieldId,
            [key]: value,
          },
        ];
        const fieldSettings = result.values.map((fieldValue) =>
          resultFieldId === fieldValue.resultFieldId ? value : fieldValue[key]
        );

        if (
          fieldSettings.every((fieldSetting) => fieldSetting) ||
          fieldSettings.every((fieldSetting) => !fieldSetting)
        ) {
          groupSettings[key] = value;
        } else {
          groupSettings[key] = result[key];
        }
      }
    }
    axios
      .put('/admin/drf/submissions/' + submissionId + '/set-states', [groupSettings], {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        this.props.setSubmissionSettings(submissionId, result.resultId, key, groupSettings);
      })
      .catch((e) => {
        this.props.throwError(e);
      });
  };

  handleEditSave = (result) => {
    const { stageId, submissionId, postResults, token } = this.props;
    postResults(submissionId, stageId, token, [result], () => {
      this.setState({ editMode: false });
    });
  };

  render() {
    const { fields, groups, groupMetaOptions, result, search, submission } = this.props;
    const { editMode } = this.state;
    const group = groups[result.groupId];

    const filteredFieldMeta = search
      ? group.fieldMeta.filter(({ fieldId }) => {
          const field = fields[fieldId];
          const fieldResult = result.values.find((resultValue) => resultValue.fieldId === fieldId) || {};
          const lowerCaseSearch = search.toLowerCase();
          const actualLabel =
            !field.label || translate(field.label).length === 0 ? translate(field.placeholder) : translate(field.label);
          return (
            (fieldResult.value &&
              getNiceValueFormat(field, fieldResult.value).toString().toLowerCase().includes(lowerCaseSearch)) ||
            actualLabel.toLowerCase().includes(lowerCaseSearch)
          );
        })
      : group.fieldMeta;
    if (filteredFieldMeta.length === 0) {
      return null;
    }

    const groupHidden = 'hidden' in result ? result.hidden : groupMetaOptions.hidden;
    const groupReadonly = 'readonly' in result ? result.readonly : groupMetaOptions.readonly;
    return (
      <div className="admin-data-section-container">
        <div className="group-header">
          <div className={'group-header-title ' + (result.state ? result.state : '')}>
            <div className={`img-container eye ${result.resultId ? '' : 'inactive'}`}>
              <Svg
                src={groupHidden ? eye_no : eye}
                hasHover={true}
                onClick={() => this.setFieldSettings('hidden', !groupHidden, result)}
              />
            </div>
            <span>{translate(group.label)}</span>
          </div>
          <div className={`switcher ${result.resultId ? '' : 'inactive'}`}>
            <label className="switch">
              <input
                type="checkbox"
                checked={groupReadonly ? true : false}
                onChange={() => this.setFieldSettings('readonly', !groupReadonly, result)}
              />
              <span className={`slider round ${groupReadonly ? 'off' : 'on'}`}></span>
            </label>
            <Translate
              className={`switcher-status ${groupReadonly ? 'off' : 'on'}`}
              value={groupReadonly ? 'datacheck/inactive' : 'datacheck/active'}
            />
          </div>
          <div className={`dropdown state-selector ${result.resultId ? '' : 'invisible'}`}>
            <StatusSelector
              state={result.state}
              isDisabled={this.props.submission.completed}
              handleSelectChange={(event) => this.handleSelectChange(event, result)}
            />
          </div>
          {editMode && (
            <div className={`img-container edit ${this.props.submission.completed ? 'inactive' : ''}`}>
              <Svg src={cancel} hasHover={true} onClick={() => this.setState((state) => ({ editMode: false }))} />
            </div>
          )}
          <div className={`img-container edit ${this.props.submission.completed ? 'inactive' : ''}`}>
            {editMode ? (
              <Svg src={save} hasHover={true} onClick={() => this.handleEditSave(result)} />
            ) : (
              <Svg src={edit} hasHover={true} onClick={() => this.setState((state) => ({ editMode: true }))} />
            )}
          </div>
          <div className="img-container message">
            {!groupHidden && (
              <Comment
                comment={result.comment}
                submissionId={this.props.submissionId}
                target={{ resultId: result.resultId }}
                targetName={translate(group.label)}
                targetUrl={'/comment'}
              />
            )}
          </div>
        </div>
        {editMode ? (
          <div className="form-container edit-group-container">
            <Group
              groupMeta={{ options: null }}
              groupResults={[result]}
              group={group}
              submissionId={this.props.submissionId}
              key={group._id}
              adminEditMode={true}
            />
          </div>
        ) : (
          <div className="content-rows" key={result._id}>
            {filteredFieldMeta.map(({ fieldId }, i) => {
              const field = fields[fieldId];
              if (field.type === 'separator') return null;
              const value = result.values.find((v) => v.fieldId === fieldId) || {};
              const fieldState = value.state || result.state;
              const defaultHidden = 'hidden' in group.fieldMeta.find((fm) => fm.fieldId === fieldId).options;
              const fieldHidden = 'hidden' in value ? value.hidden : groupHidden || defaultHidden;
              let isReferencedField = false;
              if (result.referenceResultId) {
                const referenceResult =
                  submission.data && submission.data.find((r) => r.resultId === result.referenceResultId);
                if (
                  referenceResult &&
                  referenceResult.values &&
                  referenceResult.values.find((v) => v.fieldId === fieldId)
                ) {
                  isReferencedField = true;
                }
              }
              const fieldReadonly = ('readonly' in value ? value.readonly : groupReadonly) || isReferencedField;
              return (
                <div className="row" key={i}>
                  <div className={`cell ${value.resultFieldId ? '' : 'disabled'}`}>
                    <Svg
                      hasHover={true}
                      src={fieldHidden ? eye_no : eye}
                      onClick={() => this.setFieldSettings('hidden', !fieldHidden, result, value.resultFieldId)}
                    />
                  </div>
                  <div className="cell label">
                    {!field.label || translate(field.label).length === 0
                      ? translate(field.placeholder)
                      : translate(field.label)}
                    :
                  </div>
                  <div className="cell">
                    <span>{getNiceValueFormat(field, value.value)}</span>
                  </div>
                  <div className={`cell ${value.resultFieldId && !isReferencedField ? '' : 'disabled'}`}>
                    <div className="table-cell switcher">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={fieldReadonly ? true : false}
                          onChange={() =>
                            this.setFieldSettings('readonly', !fieldReadonly, result, value.resultFieldId)
                          }
                        />
                        <span className={`slider round ${fieldReadonly ? 'off' : 'on'}`}></span>
                      </label>
                      <Translate
                        className={`switcher-status ${fieldReadonly ? 'off' : 'on'}`}
                        value={fieldReadonly ? 'datacheck/inactive' : 'datacheck/active'}
                      />
                    </div>
                  </div>
                  <div className="cell">
                    <div className={`dropdown state-selector ${value.resultFieldId ? '' : 'invisible'}`}>
                      <StatusSelector
                        state={fieldState}
                        isDisabled={this.props.submission.completed}
                        handleSelectChange={(event) => this.handleSelectChange(event, result, value.resultFieldId)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

AdminGroupResult.propTypes = {
  token: PropTypes.string,
  groups: PropTypes.object,
  fields: PropTypes.object,
  setSubmissionSettings: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const submission = state.submissions[ownProps.submissionId] || {};
  return {
    token: state.auth.token,
    groups: state.modules.groups,
    fields: state.modules.fields,
    submission,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSubmissionSettings: submissionActions.setSubmissionSettings,
      postResults,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminGroupResult);
