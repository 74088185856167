import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { setSelectorDirectionClass } from '../../helper/functions';

import './StatusSelector.scss';

const selectOptions = [
  {
    value: "pending",
    label: "pending"
  }, {
    value: "approved",
    label: "approved"
  }, {
    value: "rejected",
    label: "rejected"
  }
];

const StatusSelector = ({ state, handleSelectChange, isDisabled  }) => (
  <Select
    className={"select-container status-selector-container " + state}
    classNamePrefix="subject-select"
    placeholder="Choose!"
    isDisabled={isDisabled}
    options={selectOptions}
    onChange={handleSelectChange}
    value={{ value: state, label: state }}
    isSearchable={false}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: 'transparent',
      },
    })}
    onMenuClose={setSelectorDirectionClass}
    onMenuOpen={setSelectorDirectionClass}
    menuPlacement="auto"
  />
);

StatusSelector.propTypes = {
  state: PropTypes.string
};

export default StatusSelector;