import { types } from '../actions/entity';
import { types as authTypes } from '../actions/auth';

export default function (
  state = {
    entities: [],
    isLoaded: false,
    entity: {},
  },
  action
) {
  switch (action.type) {
    case types.SET_ENTITIES:
      return {
        ...state,
        entities: action.data,
        isLoaded: true,
      };
    case types.SET_ENTITY:
      return {
        ...state,
        entity: action.data,
      };
    case types.REQUEST_ENTITIES:
      return {
        ...state,
        isLoaded: false,
        entities: [],
      };
    case authTypes.LOGOUT:
      return {
        entities: [],
      };
    default:
      return state;
  }
}
