import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import ClientDocumentTable from '../ClientDocumentTable';
import Authorization from '../Authorization';
import TextWithTooltip from '../TextWithTooltip';
import SubHeader from '../SubHeader';
import ViewOptions from '../ViewOptions';
import Filterbar from '../Filterbar';

import { translate, downloadDocumentWithBlob } from '../../helper/functions';

import './DataVault.scss';

const DataVault = (props) => {
  const { token, documents, subPageTitle, modules, submissions } = props;

  const defaultSearchAndFilter = {
    search: '',
    module: '',
  };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  useEffect(() => {
    setFilteredDocuments(
      documents
        .map((d) => ({ ...d, moduleId: submissions.find((s) => s._id === d.submissionId).moduleId }))
        .filter((doc) => onlyNotHidden(doc) && onlyMatchingLabels(doc) && onlyMatchingModule(doc))
    );
  }, [documents, searchAndFilter]);

  const onlyNotHidden = (doc) => !doc.hidden;
  const htmlElements = /<(.*?)>/g;
  const onlyMatchingLabels = (doc) =>
    translate(doc.label).replace(htmlElements, '').toLowerCase().includes(searchAndFilter.search.toLowerCase());
  const onlyMatchingModule = (doc) => ['', 'all', doc.moduleId].includes(searchAndFilter.module);
  const downloadDocument = (doc, withFilePath = false) => {
    downloadDocumentWithBlob(doc, doc.submissionId, token, withFilePath);
  };

  const existingModules = [
    ...new Set(documents.map((d) => submissions.find((s) => s._id === d.submissionId).moduleId)),
  ];

  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredDocuments.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () =>
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        }),
    },
    selects: [
      ...(existingModules.length > 1
        ? [
            {
              name: 'module',
              className: 'module-selector',
              selected: searchAndFilter.module,
              onSelectChange: (selectValue) =>
                setSearchAndFilter({
                  ...searchAndFilter,
                  module: selectValue,
                }),
              options: existingModules.map((m) => ({ value: m, label: translate(modules[m].label) })),
            },
          ]
        : []),
    ],
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };

  return (
    <Fragment>
      <SubHeader>
        <div className="column">
          <div className="title">
            <TextWithTooltip textWithOverflow={subPageTitle} />
          </div>
        </div>
        <div className="column">
          <ViewOptions options={['toggleFilterBar']} />
        </div>
      </SubHeader>
      <Filterbar {...filterbarProps} />
      <div className="client-table-container hide-scrollbar">
        <ClientDocumentTable
          documents={filteredDocuments}
          downloadDocument={downloadDocument}
          showDownloadAndUpload={false}
        />
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    modules: state.modules.modules,
  };
}

export default Authorization(connect(mapStateToProps, null)(DataVault), ['list-submission-documents']);
