import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';

import store from './store';

import Dashboard from './components/Dashboard';
import ForgottenPassword from './components/ForgottenPassword';
import Layout from './components/Layout';
import LoginPage from './components/LoginPage';
import ProfilePage from './components/ProfilePage';
import RegistrationPage from './components/RegistrationPage';
import ResetPassword from './components/ResetPassword';

export default (
  <Provider store={store}>
    <Router>
        <Layout className="layout-wrapper">
          <Switch>
          <Route exact path="/registration" component={RegistrationPage}/>
          <Route exact path="/login" component={LoginPage}/>
          <Route exact path="/forgottenpassword" component={ForgottenPassword}/>
          <Route exact path="/reset-password" component={ResetPassword}/>
          <Route exact path="/profile" component={ProfilePage}/>
          <Route path="/" component={Dashboard}/>
        </Switch>
        </Layout>
    </Router>
  </Provider>
)
