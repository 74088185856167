import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import SubHeader from '../SubHeader';
import ResponsiveTable from '../ResponsiveTable';
import Authorization from '../Authorization';
import TextWithTooltip from '../TextWithTooltip';
import Filterbar from '../Filterbar';
import NavigateTabs from '../NavigateTabs';
import ViewOptions from '../ViewOptions';
import ResponsiveTileView from '../ResponsiveTileView';

import { getDateInLocalFormat, filterBy } from '../../helper/functions';
import * as errorActions from '../../actions/error';

import './Users.scss';

const Users = (props) => {
  const { token, routes, viewMode, throwError } = props;
  const [data, setData] = useState({ users: [], fetched: false });
  const [filteredData, setFilteredData] = useState([]);
  const headerColumns = [
    { name: I18n.t('users-table/table-header-1'), class: 'col-4', sortName: 'clientName' },
    { name: I18n.t('users-table/table-header-2'), class: 'col-3', sortName: 'email' },
    { name: I18n.t('users-table/table-header-3'), class: '', sortName: 'created' },
    { name: I18n.t('users-table/table-header-4'), class: '', sortName: 'lastLogin' },
  ];
  const defaultSearchAndFilter = { search: '' };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);

  useEffect(() => {
    if (!data.fetched) {
      fetchData();
    }
    setFilteredData(
      data.users.filter((u) =>
        filterBy(
          u,
          [{ type: 'text', itemProperties: ['profile.name', 'email'], searchProperty: 'search' }],
          searchAndFilter
        )
      )
    );
  }, [data.fetched, searchAndFilter]);

  const fetchData = async () => {
    try {
      const response = await axios.get('/admin/users?loginEnabled=true&role=user', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setData({
        users: response.data,
        fetched: true,
      });
    } catch (e) {
      throwError(e);
    }
  };
  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredData.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        });
      },
    },
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };
  const componentName = 'users';

  return (
    <div className="users-container container">
      <SubHeader>
        <div className="column">
          <NavigateTabs routes={routes} />
        </div>
        <div className="column">
          <ViewOptions componentName={componentName} />
        </div>
      </SubHeader>
      <Filterbar {...filterbarProps} />
      {viewMode[componentName] === 'tile' ? (
        <ResponsiveTileView
          tableHeaderColumns={headerColumns.map((hc) => hc.sortName)}
          type="user"
          tileContent={filteredData}
          defaultSort="clientName"
        />
      ) : (
        <ResponsiveTable
          headerColumns={headerColumns}
          data={filteredData}
          renderRow={(user) => (
            <div key={user._id} className="table-row">
              <div className="column col-4 main-color align-left bigger-padding sticky">
                <TextWithTooltip textWithOverflow={user.profile.name} />
              </div>
              <div className="column col-3 main-color bigger-padding">
                <TextWithTooltip textWithOverflow={user.email} />
              </div>
              <div className="column">{getDateInLocalFormat(user.created)}</div>
              <div className="column">{getDateInLocalFormat(user.lastLogin)}</div>
            </div>
          )}
        ></ResponsiveTable>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    viewMode: state.layout.viewMode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}
export default Authorization(connect(mapStateToProps, mapDispatchToProps)(Users), ['list-users']);
