import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import Comment from '../Comment';
import StatusIndicator from '../StatusIndicator';
import Svg from '../Svg';
import ResponsiveTable from '../ResponsiveTable';

import { translate } from '../../helper/functions';

import document from '../../assets/document_icon.svg';
import upload from '../../assets/upload_icon.svg';
import download from '../../assets/download_icon.svg';
import loader from '../../assets/loader_circle_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import info_icon from '../../assets/info_icon_blue.svg';

import './ClientDocumentTable.scss';

class ClientDocumentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTableRows: [],
      dropdownOpenByDocId: '',
      documentsSortedBy: 'label',
      isAscendingSort: true,
    };
    this.headerColumns = [
      { name: I18n.t('document-library/table-header-1'), class: 'document-name', sortName: 'label' },
      { name: I18n.t('document-library/table-header-2'), class: 'document-icon', sortName: 'generatedFilePath' },
      { name: I18n.t('document-library/table-header-3'), class: 'document-icon', sortName: 'filePath' },
      { name: I18n.t('document-library/table-header-6'), class: 'document-icon', sortName: 'stateAndFilePath' },
      { name: I18n.t('document-library/table-header-7'), class: 'comment' },
      { name: I18n.t('document-library/table-header-5'), class: 'info' },
    ];
  }

  openTableRow(rowToOpen) {
    let openRows = [...this.state.openTableRows];
    if (openRows.includes(rowToOpen)) {
      openRows.splice(openRows.indexOf(rowToOpen), 1);
    } else {
      openRows.push(rowToOpen);
    }
    this.setState({
      openTableRows: openRows,
    });
  }

  render() {
    const {
      documents,
      uploadDocument,
      downloadDocument,
      ableToUploadAndDelete,
      openDeletePopup,
      submission,
    } = this.props;

    return (
      <ResponsiveTable
        className="client-document-table"
        headerColumns={this.headerColumns}
        data={documents}
        defaultSort="label"
        isAscending={true}
        renderRow={(d, options) => {
          return (
            <div className="table-row" key={d._id}>
              <div className={`column document-name sticky ${d.state}`}>
                <div dangerouslySetInnerHTML={{ __html: translate(d.label) }} />
              </div>
              <div className={`column document-icon ${d.readonly ? 'inactive' : ''}`}>
                {d.dataFlow !== 'upload' &&
                  (d.generatedFilePath ? (
                    <Svg onClick={() => downloadDocument(d)} className={'active'} src={download} hasHover={true} />
                  ) : (
                    <Svg className={'download rotating'} src={loader} hasHover={true} />
                  ))}
              </div>
              <div
                className={`column document-icon upload ${d.readonly || d.dataFlow === 'download' ? 'inactive' : ''}`}
              >
                {d.filePath ? (
                  <Svg className="doc-icon" src={document} hasHover={true} onClick={() => downloadDocument(d, true)} />
                ) : ableToUploadAndDelete && !d.uploading ? (
                  <label
                    htmlFor={'file-upload' + options.index}
                    className={`custom-file-upload ${
                      submission && submission.isBlocked && d.state === 'pending' ? 'inactive' : ''
                    }`}
                  >
                    <Svg src={upload} hasHover={true} />
                    <input
                      id={'file-upload' + options.index}
                      disabled={submission && submission.isBlocked && d.state === 'pending'}
                      type="file"
                      name={d._id}
                      onChange={uploadDocument}
                    />
                  </label>
                ) : d.uploading ? (
                  <Svg className={'rotating'} src={loader} hasHover={true} />
                ) : null}
                {d.filePath &&
                  ableToUploadAndDelete &&
                  d.state !== 'approved' &&
                  (!submission || (submission && !submission.isBlocked) || d.state !== 'pending') && (
                    <img onClick={() => openDeletePopup(d)} className="delete-icon" src={delete_icon} />
                  )}
              </div>
              <div className="column document-icon status">
                {(d.filePath || (d.generatedFilePath && d.dataFlow === 'download')) && (
                  <StatusIndicator state={d.state} />
                )}
              </div>
              <div className="column comment">
                {d.comment && d.comment.comment && (
                  <Comment comment={d.comment} targetName={translate(d.label)} alternateColor={true} />
                )}
              </div>
              <div className="column info">
                <Svg
                  className={!d.filePath && !d.description ? 'inactive' : ''}
                  src={info_icon}
                  onClick={() => {
                    if (d.filePath || d.description) {
                      this.openTableRow(d._id);
                    }
                  }}
                  hasHover={d.filePath || d.description}
                />
              </div>
              <div className="break"></div>
              {this.state.openTableRows.includes(d._id) && (
                <div className="column document-description">
                  <div className="inner-container" style={{ width: options.containerWidth }}>
                    <div className={`desc ${d.state}`}>
                      <div className="desc-content">
                        {d.description && <div dangerouslySetInnerHTML={{ __html: translate(d.description) }} />}
                      </div>
                    </div>
                    <div className="uploaded-doc">
                      <div className="uploaded-doc-name">
                        <span>{I18n.t('document-library/uploaded-label')}:</span>
                      </div>
                      <div className="uploaded-doc-desc">
                        <span>{d.fileName || '-'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      ></ResponsiveTable>
    );
  }
}

ClientDocumentTable.propTypes = {
  documents: PropTypes.array,
  uploadDocument: PropTypes.func,
  downloadDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
};

export default ClientDocumentTable;
