import axios from 'axios';
import * as submissionActions from '../actions/submissions';
import * as moduleActions from '../actions/modules';
import * as errorActions from '../actions/error';

export function getSubmission(submissionId, token) {
  return (dispatch) => {
    dispatch(submissionActions.setSubmissionLoading(submissionId, true));
    const header = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    axios
      .get('/drf/submissions/' + submissionId, header)
      .then((response) => {
        dispatch(submissionActions.setSubmissionData(response.data));
        return axios.get('/drf/modules/' + response.data.moduleId, header);
      })
      .then((response) => {
        dispatch(moduleActions.setModuleData(response.data));
      })
      .catch((error) => {
        dispatch(errorActions.throwServerError(error));
      });
  };
}

export function getDocuments(submissionId, token) {
  return (dispatch) => {
    const header = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    let documents = [];
    axios
      .get(`/drf/submissions/${submissionId}/documents`, header)
      .then((response) => {
        if (response.data.length) {
          documents = response.data;
        }
        dispatch(submissionActions.setDocuments(submissionId, response.data));
      })
      .catch((error) => {
        dispatch(errorActions.throwServerError(error));
      });
  };
}
