export const types = {
  SERVER_ERROR: 'SERVER_ERROR',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
};

export function throwServerError(error) {
  return {
    type: types.SERVER_ERROR,
    error,
  };
}

export function resetErrorMessage() {
  return {
    type: types.RESET_ERROR_MESSAGE,
  };
}
