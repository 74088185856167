import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import FormGroups from '../FormGroups';
import DataCheckStage from '../DataCheckStage';
import DocumentLibrary from '../DocumentLibrary';
import StageList from '../StageList';
import NavigationButtons from '../NavigationButtons';
import StoppedStage from '../StoppedStage';
import BackButton from '../BackButton';
import Popup from '../Popup';
import InputWithError from '../InputWithError';
import DeleteButton from '../DeleteButton';
import Authorization from '../Authorization';

import * as entityThunks from '../../thunks/entity';
import * as moduleActions from '../../actions/modules';
import * as submissionThunks from '../../thunks/submission';

import { translate, formValidator } from '../../helper/functions';

import pwd_by_consolidity_logo from '../../assets/pwd_by_consolidity.svg';

import './Stage.scss';

class Stage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      popup: {
        shown: false,
        password: '',
        errors: {},
      },
    };
  }

  closePopup = () => {
    this.setState((state) => {
      return {
        ...state,
        popup: {
          ...state.popup,
          shown: false,
          password: '',
          errors: {},
        },
      };
    });
  };

  handleInputChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    this.setState((state) => {
      return {
        ...state,
        popup: {
          ...state.popup,
          password: value,
          errors: { ...state.popup.errors, ...formValidator(name, value) },
        },
      };
    });
  };

  deleteSubmission = () => {
    axios
      .delete('/drf/submissions/' + this.props.match.params.submissionId, {
        data: {
          currentPassword: this.state.popup.password,
        },
        headers: {
          Authorization: 'Bearer ' + this.props.token,
        },
      })
      .then((response) => {
        this.props.fetchEntities();
        this.closePopup();
        this.props.history.push('/');
      })
      .catch((e) => {
        this.setState((state) => {
          return {
            ...state,
            popup: {
              ...state.popup,
              shown: true,
              errors: { ...state.popup.errors, password: I18n.t(e.response.data.error) },
            },
          };
        });
      });
  };

  componentDidMount = () => {
    this.props.getSubmission(this.props.match.params.submissionId, this.props.token);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.submissionId !== prevProps.match.params.submissionId) {
      this.props.getSubmission(this.props.match.params.submissionId, this.props.token);
    }
  };

  render() {
    const { stage, stages, stageMeta, params, submission, submissions, modules } = this.props;
    const { popup } = this.state;
    const popupOkDisabled = !!popup.errors.password || popup.password.length < 1;
    if (!stage || !submission) return null;
    const entityId = submission.entityId;
    const isBlocked = submission.isBlocked;
    const blockContent = isBlocked && stage.blockContent ? translate(stage.blockContent) : null;
    const blockDescription = isBlocked && stage.description ? translate(stage.description) : null;
    const isDisabledDeleteButton =
      params.submissionId &&
      submissions[params.submissionId] &&
      submissions[params.submissionId].moduleId &&
      modules.modules[submissions[params.submissionId].moduleId].autoCreateEntity;
    const stagePosition = stageMeta.length > 0 ? stageMeta.find((sm) => sm.stageId == stage._id).position : -1;

    const filteredStageMeta = stageMeta.filter((sm) => {
      const stageInList = stages[sm.stageId];
      if (stageInList.type !== 'data-input') {
        return true;
      }
      const groupMetaExtendedWithHidden = stageInList.groupMeta.map((gm) => {
        let groupResult = submission.data.find((result) => result.groupId === gm.groupId);
        return {
          ...gm,
          hidden: groupResult && 'hidden' in groupResult ? groupResult.hidden : gm.options && gm.options.hidden,
        };
      });
      return groupMetaExtendedWithHidden.some((g) => !g.hidden);
    });

    const blockedPosition = filteredStageMeta.findIndex((sm) => sm.stageId === isBlocked);

    const filteredStageMetaIds = filteredStageMeta.map((sm) => sm.stageId);
    if (!filteredStageMetaIds.includes(stage._id)) {
      if (filteredStageMetaIds.includes(submission.stageId)) {
        return <Redirect to={`${submission.stageId}`} />;
      } else if (stageMeta && stageMeta.length) {
        const firstStage = [...stageMeta].sort((a, b) => a.position - b.position)[0];
        if (firstStage && firstStage.stageId) {
          return <Redirect to={`${firstStage.stageId}`} />;
        }
      }
    }

    let stageComponent = null;
    if (isBlocked && blockedPosition < 0 && blockedPosition <= stagePosition) {
      stageComponent = (
        <StoppedStage blockContent={blockContent} isBlocked={isBlocked} submissionId={params.submissionId} />
      );
    } else {
      switch (stage.type) {
        case 'data-input':
          stageComponent = <FormGroups groupMeta={stage.groupMeta} params={params} />;
          break;
        case 'document-library':
          stageComponent = <DocumentLibrary groupMeta={stage.groupMeta} params={params} />;
          break;
        case 'summary':
          stageComponent = <DataCheckStage params={params} filteredStageMeta={filteredStageMeta} />;
          break;
        case 'manual-override':
          stageComponent = (
            <StoppedStage
              isBlocked={isBlocked}
              blockContent={blockContent}
              blockDescription={blockDescription}
              submissionId={params.submissionId}
            />
          );
          break;
        default:
      }
    }

    let stageIsBlocked = isBlocked || stage.type === 'manual-override';
    let stageDescriptionShown = translate(stage.description) && !stageIsBlocked;

    let deleteButton = {
      text: I18n.t('submission/delete-submission'),
      onClick: () =>
        this.setState((state) => {
          return { ...state, popup: { ...state.popup, shown: true } };
        }),
      rules: ['delete-submission'],
      disabled: isDisabledDeleteButton,
      tooltipText: I18n.t('submission/delete-not-allowed'),
    };

    return (
      <div className="stage-container">
        <Popup
          popupShown={popup.shown}
          popupTitle={I18n.t('delete-process/popup-title')}
          cancelHandler={this.closePopup}
          okHandler={this.deleteSubmission}
          okDisabled={popupOkDisabled}
        >
          <div className="form-container">
            <span>{I18n.t('delete-process/popup-text')}</span>
            <InputWithError
              placeholder={I18n.t('form/password')}
              name="password"
              type="password"
              value={popup.password}
              onChange={this.handleInputChange}
              errorMsg={popup.errors.password}
            />
          </div>
        </Popup>
        <div className="sidebar">
          <BackButton title={I18n.t('submission/back-button')} backUrl={`/entity/${entityId}/submissions`} />
          <StageList stage={stage} filteredStageMeta={filteredStageMeta} params={params} history={this.props.history} />
          <div className="sidebar-bottom">
            <DeleteButton {...deleteButton} />
            <div className="pwd-by-consolidity">
              {clientConfig && clientConfig.poweredBy && <img src={pwd_by_consolidity_logo}></img>}
            </div>
          </div>
        </div>
        <div className="stage">
          <div className="navigation">
            <div className="header-title">
              <h2 dangerouslySetInnerHTML={{ __html: translate(stage.label) }}></h2>
            </div>
            <NavigationButtons
              stage={stage}
              filteredStageMeta={filteredStageMeta}
              submission={submission}
              params={params}
              history={this.props.history}
            />
          </div>
          <div className={'form-container scroll-to-top hide-scrollbar ' + (stageIsBlocked ? 'blocked-stage' : '')}>
            {stageDescriptionShown && (
              <div className="description-box">
                <span dangerouslySetInnerHTML={{ __html: translate(stage.description) }}></span>
              </div>
            )}
            {stageComponent}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { params } = ownProps.match;
  const { history } = ownProps;
  const submission = state.submissions[params.submissionId] || null;
  const stageMeta =
    submission && state.modules.modules[submission.moduleId] && state.modules.modules[submission.moduleId].stageMeta
      ? state.modules.modules[submission.moduleId].stageMeta
      : [];
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    modules: state.modules,
    stage: state.modules.stages[params.stageId] || null,
    stages: state.modules.stages,
    submissions: state.submissions,
    submission,
    stageMeta,
    params,
    history,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSubmission: submissionThunks.getSubmission,
      fetchEntities: entityThunks.fetchEntities,
      setModuleData: moduleActions.setModuleData,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(Stage), ['edit-submission']);
