import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';

import Header from '../Header';
import InputWithError from '../InputWithError';
import Popup from '../Popup';
import ReactSelectWrapper from '../ReactSelectWrapper';
import WrappedLink from '../WrappedLink';

import * as authActions from '../../actions/auth';
import { formValidator, getBrowserLang } from '../../helper/functions';

import './RegistrationPage.scss';

class RegistrationPage extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        name: '',
        email: '',
        password: '',
        claim: '',
        confirmPassword: '',
        locale: '',
        privacy: false,
        terms: false,
      },
      popupShown: false,
      errors: {},
    };
    this.headerLinks = [{ to: '/login', translateKey: 'menu/login' }];
  }

  componentDidMount() {
    if (!this.state.locale) {
      this.setState((state) => ({
        ...state,
        formData: {
          ...state.formData,
          locale:
            clientConfig.locales.length > 1 && clientConfig.locales.includes(getBrowserLang())
              ? getBrowserLang()
              : clientConfig.locale,
        },
      }));
    }
    if (this.props.authenticated) {
      this.props.history.push('/');
    }
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => {
      return {
        formData: {
          ...state.formData,
          [name]: value,
        },
        errors: {
          ...state.errors,
          ...formValidator(
            target.name,
            target.value,
            target.name === 'confirmPassword' && this.state.formData.password
          ),
        },
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('/auth/registration', {
        ...this.state.formData,
        type: 'email',
      })
      .then((response) => {
        if (clientConfig.manualRegistration) {
          this.setState((state) => {
            return {
              ...state,
              popupShown: true,
            };
          });
        } else {
          this.props.login(response.data);
          this.props.history.push('/');
        }
      })
      .catch((error) => {
        if (error.response.data.error === 'EMAIL_ALREADY_EXISTS') {
          this.setState((state) => {
            return {
              ...state,
              errors: {
                ...state.errors,
                email: I18n.t('registration/email-already-exists'),
              },
            };
          });
        }
        console.log(error);
      });
  };

  popupOkHandler = () => {
    this.setState((state) => {
      return {
        ...state,
        popupShown: false,
      };
    });
    this.props.history.push('/');
  };

  render() {
    const { manualRegistration } = clientConfig;
    const { formData, errors, popupShown } = this.state;
    const formValid =
      Object.keys(errors)
        .map((k) => errors[k])
        .filter((e) => e).length === 0 &&
      Object.keys(formData)
        .filter((f) => typeof formData[f] === 'string' && f !== 'claim')
        .every((f) => Boolean(formData[f])) &&
      Object.values(formData).filter((f) => f === false).length === 0;

    return (
      <div className="authentication-page-content">
        <Popup popupShown={popupShown} popupImg="success" okHandler={this.popupOkHandler}>
          <span>{I18n.t('registration/popup-title')}</span>
        </Popup>
        <Header links={this.headerLinks} />
        <h1>
          <Translate value="registration/title" />
        </h1>
        <div className="form-wrapper">
          <div className="form-container">
            <form onSubmit={this.handleSubmit}>
              <InputWithError
                placeholder={I18n.t('form/name')}
                name="name"
                type="name"
                value={formData.name}
                onChange={this.handleInputChange}
                errorMsg={errors.name}
              />
              <InputWithError
                placeholder={I18n.t('form/email')}
                name="email"
                type="email"
                value={formData.email}
                onChange={this.handleInputChange}
                errorMsg={errors.email}
              />
              <InputWithError
                placeholder={I18n.t('form/password')}
                name="password"
                type="password"
                value={formData.password}
                onChange={this.handleInputChange}
                errorMsg={errors.password}
              />
              <InputWithError
                placeholder={I18n.t('form/password-again')}
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={this.handleInputChange}
                errorMsg={errors.confirmPassword}
              />
              {clientConfig.locales.length > 1 && (
                <div className="dropdown-container">
                  <ReactSelectWrapper
                    onChange={(e) =>
                      this.setState((state) => ({ ...state, formData: { ...state.formData, locale: e.value } }))
                    }
                    placeholder={I18n.t('form/select-locale')}
                    options={clientConfig.locales.map((l) => ({ label: I18n.t(`form/${l}`), value: l }))}
                    value={{ label: I18n.t(`form/${formData.locale}`), value: formData.locale }}
                  />
                </div>
              )}
              {manualRegistration && (
                <div className={'textarea-container ' + (this.state.errors.claim ? 'error' : '')}>
                  <textarea
                    placeholder="Please specify the details of your interest."
                    name="claim"
                    value={formData.claim}
                    onChange={this.handleInputChange}
                  />
                  <div className="error-message">{this.state.errors.claim}</div>
                </div>
              )}
              <div className="checkbox-container">
                <label className="checkbox-line">
                  <input
                    name={'terms'}
                    type={'checkbox'}
                    checked={this.state.terms}
                    onChange={this.handleInputChange}
                  />
                  <span className="checkmark"></span>
                  {I18n.t('registration/agree')}{' '}
                  <a target="_blank" href={I18n.t('registration/terms-and-conditions-link')}>
                    {I18n.t('registration/terms-and-conditions')}
                  </a>
                </label>
                <label className="checkbox-line">
                  <input
                    name={'privacy'}
                    type={'checkbox'}
                    checked={this.state.privacy}
                    onChange={this.handleInputChange}
                  />
                  <span className="checkmark"></span>
                  {I18n.t('registration/agree')}{' '}
                  <a target="_blank" href={I18n.t('registration/privacy-link')}>
                    {I18n.t('registration/privacy')}
                  </a>
                </label>
              </div>
              <div className="button-container more-children">
                <WrappedLink to={'/login'}>
                  <Translate className="cancel" value="form/cancel" />
                </WrappedLink>
                <button type="submit" className="medium" disabled={!formValid}>
                  <Translate value="registration/title" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

RegistrationPage.propTypes = {
  login: PropTypes.func,
  authenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: authActions.login,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
