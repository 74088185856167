import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DatePicker from 'react-datepicker';
import FormTooltip from '../FormTooltip';
import moment from 'moment';

import ReactSelectWrapper from '../ReactSelectWrapper';
import Esign from '../Esign';

import * as submissionActions from '../../actions/submissions';

import {
  translate,
  fieldValidator,
  setSelectorDirectionClass,
  getDateTimeFormat,
  isNumberFragment,
} from '../../helper/functions';

import './Field.scss';
import 'react-datepicker/dist/react-datepicker.css';

import error_icon from '../../assets/error_icon.svg';

class Field extends React.Component {
  constructor() {
    super();
    this.numberAndDatepickerRef = React.createRef();
    this.state = {
      unChanged: true,
      menuIsopen: false,
    };
  }

  componentDidMount() {
    this.setRequiredOrDefault();
    if (ReactDOM.findDOMNode(this.numberAndDatepickerRef.current) !== null) {
      ReactDOM.findDOMNode(this.numberAndDatepickerRef.current).addEventListener('mousewheel', function (e) {
        this.blur();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.groupId !== this.props.groupId ||
      prevProps.stageId !== this.props.stageId ||
      prevProps.submissionId !== this.props.submissionId
    ) {
      this.setRequiredOrDefault();
      this.setState({
        unChanged: true,
      });
    }
  }

  setRequiredOrDefault() {
    if (
      !this.props.value &&
      this.props.value !== false &&
      this.props.validationRules &&
      this.props.validationRules.required
    ) {
      this.props.changeValue({
        submissionId: this.props.submissionId,
        groupId: this.props.groupId,
        groupPosition: this.props.groupPosition,
        fieldId: this.props._id,
        value: this.props.type === 'checkbox' ? false : this.props.value,
        error: fieldValidator(
          this.props.value,
          this.props.validationRules,
          this.props.type,
          this.props.submission,
          this.props.groupId
        ),
      });
    }
  }

  handleChange = (e) => {
    if (this.props.options && this.props.options.disabled) {
      return;
    }

    let value = null;
    switch (this.props.type) {
      case 'text':
      case 'textarea':
        value = e.target.value;
        break;
      case 'number':
        const oldValue = this.props.value;
        if (e.target.value === '' || isNumberFragment(e.target.value)) {
          value = e.target.value;
        } else {
          value = oldValue;
        }

        break;
      case 'checkbox':
        if (this.props.value) {
          if (this.props.value.map((v) => v.id).includes(e.id)) {
            value = this.props.value.filter((v) => v.id !== e.id);
          } else {
            value = [...this.props.value, e];
          }
        } else {
          value = [e];
        }
        break;
      case 'radio':
        value = e;
        break;
      case 'date':
        const strDate = moment(e).format('MM-DD-YYYY');
        // save the dates as utc
        value = moment(strDate + ' +0000', 'MM-DD-YYYY Z').toISOString();
        break;
      case 'select':
        value = e;
        break;
      case 'esignature':
        value = e;
        break;
      default:
    }

    if (this.state.unChanged) {
      this.setState({
        unChanged: false,
      });
    }

    this.props.changeValue({
      submissionId: this.props.submissionId,
      groupId: this.props.groupId,
      groupPosition: this.props.groupPosition,
      fieldId: this.props._id,
      value,
      error: fieldValidator(
        value,
        this.props.validationRules,
        this.props.type,
        this.props.submission,
        this.props.groupId
      ),
    });
  };

  handleMenu(isOpen) {
    this.setState(
      (state) => ({
        ...state,
        menuIsopen: isOpen,
      }),
      setSelectorDirectionClass
    );
  }

  render() {
    let input = null;
    const { type, value, error, label, state, submission, options } = this.props;

    const disabled =
      (options && options.disabled) || (submission.isBlocked && (state === 'approved' || state === 'pending'));
    const placeholder =
      translate(this.props.placeholder) +
      (this.props.validationRules && this.props.validationRules.required ? '*' : '');
    switch (type) {
      case 'esignature':
        input = <Esign value={value} onChange={this.handleChange} disabled={disabled}></Esign>;
        break;
      case 'text':
      case 'number':
        input = (
          <label>
            <input
              className={'input ' + (value ? 'has-value' : '')}
              type="text"
              disabled={disabled}
              value={value || ''}
              onChange={this.handleChange}
              ref={this.numberAndDatepickerRef}
            />
            <div className="active-placeholder">
              <span>{placeholder}</span>
            </div>
          </label>
        );
        break;
      case 'checkbox':
        input = (
          <div className="checkbox-container">
            {label && translate(label) && (
              <div className="checkbox-main-label">
                {translate(label) + (this.props.validationRules && this.props.validationRules.required ? '*' : '')}
              </div>
            )}
            {this.props.values &&
              this.props.values.map((val, i) => {
                return (
                  <label key={i} className="checkbox-line">
                    <input
                      id={val.id}
                      type={type}
                      disabled={disabled}
                      checked={value ? value.map((v) => v.id).includes(val.id) : false}
                      placeholder={placeholder}
                      onChange={() => this.handleChange(val)}
                    />
                    <span className="checkmark"></span>
                    {translate(val.label)}
                  </label>
                );
              })}
          </div>
        );
        break;
      case 'textarea':
        input = (
          <label>
            <textarea
              className={value ? 'has-value' : ''}
              value={value ? value : ''}
              disabled={disabled}
              onChange={this.handleChange}
            />
            <div className="active-placeholder">
              <span>{placeholder}</span>
            </div>
          </label>
        );
        break;
      case 'date':
        input = (
          <div className={'datepicker-with-placeholder ' + (value || this.state.menuIsopen ? 'has-value' : '')}>
            <DatePicker
              dateFormat={getDateTimeFormat(clientConfig.locale)}
              selected={value ? new Date(value) : null}
              onChange={this.handleChange}
              showYearDropdown
              showMonthDropdown
              onCalendarClose={() => this.handleMenu(false)}
              onCalendarOpen={() => this.handleMenu(true)}
              disabled={disabled}
              dropdownMode="select"
              ref={this.numberAndDatepickerRef}
            />
            <span className="datepicker-placeholder">{placeholder}</span>
          </div>
        );
        break;
      case 'select':
        let filterOption = (item, search) => item.label.toLowerCase().startsWith(search.toLowerCase());
        let searchable = this.props.options && this.props.options.searchable;
        const options = this.props.referenceCollectionName
          ? this.props.referenceCollections[this.props.referenceCollectionName].map((ref) => {
              return { value: ref._id, label: translate(ref.label) };
            })
          : this.props.values.map((v) => {
              return { value: v.id, label: translate(v.label) };
            });
        if (this.props.referenceCollectionName && this.props.referenceCollectionName.includes('phone-codes')) {
          const r = /\d+/;
          filterOption = (item, search) => {
            const countryName = item.label.split(')').pop().trim();
            return countryName.toLowerCase().startsWith(search.toLowerCase()) || item.label.match(r).includes(search);
          };
          options.sort((a, b) => a.label.match(r) - b.label.match(r));
        } else {
          options.sort((a, b) => a.label.localeCompare(b.label));
        }
        input = (
          <div className="select-with-placeholder">
            <ReactSelectWrapper
              className={`hide-placeholder ${value || this.state.menuIsopen ? 'has-value' : ''}`}
              options={options}
              isDisabled={disabled}
              placeholder={placeholder}
              isMulti={!!this.props.multiple}
              onChange={this.handleChange}
              isSearchable={searchable}
              onMenuOpen={() => this.handleMenu(true)}
              onMenuClose={() => this.handleMenu(false)}
              filterOption={filterOption}
              value={value}
            >
              <span className="select-placeholder">{placeholder}</span>
            </ReactSelectWrapper>
          </div>
        );
        break;
      case 'radio':
        input = (
          <div className="radiobutton-container">
            {label && translate(label) && (
              <div className="radiobutton-main-label">
                {translate(label) + (this.props.validationRules && this.props.validationRules.required ? '*' : '')}
              </div>
            )}
            {this.props.values &&
              this.props.values.map((val, i) => {
                return (
                  <label key={i} className={'radiobutton-line ' + (disabled ? 'disabled' : '')}>
                    <input
                      id={val.id}
                      type={type}
                      disabled={disabled}
                      checked={value && value.id === val.id ? true : false}
                      placeholder={placeholder}
                      onChange={() => this.handleChange(val)}
                    />
                    <span className="checkmark"></span>
                    {translate(val.label)}
                  </label>
                );
              })}
          </div>
        );
        break;
      case 'separator':
        break;
      default:
        return null;
    }

    const errorMsg = error && !this.state.unChanged ? error : null;
    return (
      <div className="field group-wrapper-with-separator">
        {this.props.type === 'separator' ? (
          <div className={!this.props.multiline ? 'separator' : 'separator multiline'}>
            <div className="left-container">
              <div dangerouslySetInnerHTML={{ __html: translate(this.props.label) }} />
            </div>
            <div className="right-container">
              <span></span>
            </div>
          </div>
        ) : (
          <div className="form-row">
            <div className="left-container">
              <div className="field-input-with-error">
                <div className={`field-input-with-error-wrapper ${errorMsg ? 'error' : ''}`}>
                  {input}
                  {errorMsg && <img className="error-icon" src={error_icon} />}
                </div>
                <div className="error-message">{errorMsg}</div>
              </div>
            </div>
            <div className={`right-container ${disabled ? 'disabled' : ''}`}>
              <FormTooltip text={translate(this.props.description)} price={1234} currency={'€'} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

Field.propTypes = {};

function mapStateToProps(state, ownProps) {
  return {
    submission: state.submissions[ownProps.submissionId],
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    referenceCollections: state.modules.referenceCollections,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeValue: submissionActions.changeValue,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Field);
