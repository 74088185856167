import React from 'react';
import { I18n } from 'react-redux-i18n';

import './WelcomePage.scss';
import WrappedLink from '../WrappedLink/WrappedLink';

const WelcomePage = () => (
  <div className="welcome-page-container">
    <div className="welcome-page-wrapper">
      <div className="welcome-page-title">{I18n.t('welcome-page/title')}</div>
      <div className="welcome-page-text">
        <div dangerouslySetInnerHTML={{ __html: I18n.t('welcome-page/text') }} />
      </div>
      <div className="button-container">
        <WrappedLink to={'/client-management/create-new-entity'}>
          <button>{I18n.t('welcome-page/button')}</button>
        </WrappedLink>
      </div>
    </div>
  </div>
);

export default WelcomePage;
