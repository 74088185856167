import React from 'react';
import { connect } from 'react-redux';

import AdminGroupResult from '../AdminGroupResult';

import './AdminDataSummary.scss';

const AdminDataSummary = (props) => {
  const { stageMeta, submission, stages, search, submissionId } = props;

  if (!stageMeta || !submission) return null;

  return (
    <div className="admin-data-container scroll-to-top hide-scrollbar">
      {stageMeta.map(({ stageId }) => {
        const stage = stages[stageId];
        return stage.groupMeta.map(({ groupId, options = {} }) => {
          const groupResults = submission.data.filter((result) => result.groupId === groupId);
          if (groupResults.length === 0 && (options.readOnly || options.hidden)) {
            groupResults.push({
              groupId: groupId,
              position: 0,
              values: [],
            });
          }
          return groupResults.map((result, j) => {
            return (
              <AdminGroupResult
                result={result}
                groupMetaOptions={options}
                stageId={stageId}
                search={search}
                submissionId={submissionId}
                key={j}
              />
            );
          });
        });
      })}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { submissionId } = ownProps;
  const submission = state.submissions[submissionId];
  const stageMeta =
    submission && state.modules.modules[submission.moduleId]
      ? state.modules.modules[submission.moduleId].stageMeta
      : [];
  return {
    stages: state.modules.stages,
    submission,
    stageMeta,
  };
}

export default connect(mapStateToProps)(AdminDataSummary);
