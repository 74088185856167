import React from 'react';

import './SubHeader.scss';

const SubHeader = (props) => {
  const { children } = props;
  return (
    <div className="subheader">
      <div className="subheader-container">
        {children}
      </div>
    </div>
  )
};

export default SubHeader;