import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';

import * as submissionThunks from '../../thunks/submission';

import './StoppedStage.scss';

import stop from '../../assets/stop_process.svg';
import continueIcon from '../../assets/continue_process_green.svg';

const StoppedStage = (props) => {
  const { isBlocked, submissionId, token, getSubmission, blockContent, blockDescription } = props;
  const title = I18n.t('stop-process/title') + (blockDescription ? ` - ${blockDescription}` : '');
  useEffect(() => {
    getSubmission(submissionId, token);
  }, []);

  if (isBlocked === undefined) return null;
  if (isBlocked === null)
    return (
      <div className="stopped-stage-container continuable">
        <div className="stopped-stage-header">
          <img src={continueIcon}></img>
          <Translate value="stop-process/continuable-title" className="title" />
        </div>
      </div>
    );

  return (
    <div className="stopped-stage-container">
      <div className="stopped-stage-header">
        <img src={stop}></img>
        <span className="title">{title}</span>
      </div>
      <div
        className="stopped-stage-content"
        dangerouslySetInnerHTML={{ __html: isBlocked.match(/^[0-9a-fA-F]{24}$/) ? blockContent : isBlocked }}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSubmission: submissionThunks.getSubmission,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StoppedStage);
