import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import AdminSubmissionView from '../AdminSubmissionView';
import ModuleSelector from '../ModuleSelector';
import Stage from '../Stage';
import EntityDetails from '../EntityDetails';
import EntityList from '../EntityList';

const ClientManagement = ({ match }) => {
  const mainRoute = match.url === '/' ? '' : match.url;

  return (
    <Switch>
      <Route path={`${mainRoute}/admin/:submissionId/`} component={AdminSubmissionView} />
      <Route
        path={`${mainRoute}/create-new-entity/:moduleId?`}
        render={(routeProps) => <ModuleSelector {...routeProps} filterAutoCreate={false} />}
      />
      <Route path={`${mainRoute}/entity/:entityId`} component={EntityDetails} />
      <Route path={`${mainRoute}/:submissionId/:stageId/:groupId?`} component={Stage} />
      <Route path={`${mainRoute}/:submissionId/:stageId`} component={Stage} />
      <Route path={`${mainRoute}/`} component={EntityList} />
      <Redirect from={``} to={`/`} />
    </Switch>
  );
};

export default ClientManagement;
