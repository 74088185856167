import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Translate, I18n } from 'react-redux-i18n';

import AdminForm from '../AdminForm';
import AdminsTable from '../AdminsTable';
import Popup from '../Popup';
import SubHeader from '../SubHeader';
import Authorization from '../Authorization';
import AuthComponentWrapper from '../AuthComponentWrapper';
import Filterbar from '../Filterbar';
import NavigateTabs from '../NavigateTabs';
import ViewOptions from '../ViewOptions';

import { translate, filterBy } from '../../helper/functions';
import * as errorActions from '../../actions/error';

import './Admins.scss';

import add from '../../assets/add_white.svg';

const user = {
  name: '',
  email: '',
  claim: '',
  role: '',
};
const reducer = (state, { field, value, clear }) => {
  if (clear) {
    return user;
  }
  return {
    ...state,
    [field]: value,
  };
};

const Admins = (props) => {
  const { token, history, routes, throwError } = props;
  const [admins, setAdmins] = useState({ data: [], fetched: false });
  const defaultSearchAndFilter = {
    search: '',
    role: '',
  };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);
  const [isPopupOpen, setPopupVisibility] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formState, dispatch] = useReducer(reducer, user);
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [roles, setRoles] = useState({ data: [], fetched: false });

  useEffect(() => {
    getAdmins();
    getRoles();
  }, []);
  useEffect(() => {
    if (!isPopupOpen && admins.fetched) {
      getAdmins();
    }
  }, [isPopupOpen]);
  useEffect(() => {
    setFilteredAdmins(
      admins.data.filter((a) =>
        filterBy(
          a,
          [
            { type: 'text', itemProperties: ['profile.name', 'email'], searchProperty: 'search' },
            { type: 'selector', itemProperties: ['profile.role'], searchProperty: 'role' },
          ],
          searchAndFilter
        )
      )
    );
  }, [admins, searchAndFilter]);

  const okHandler = () => {
    if (formValid) {
      addAdmin();
    }
  };
  const cancelHandler = () => {
    dispatch({ field: null, value: null, clear: true });
    setPopupVisibility(false);
  };
  const headers = {
    Authorization: 'Bearer ' + token,
  };
  const addAdmin = async () => {
    try {
      const response = await axios.post('/admin/users/', formState, { headers });
      if (response.status === 200) {
        setPopupVisibility(false);
      }
    } catch (e) {
      throwError(e);
    }
  };
  const getAdmins = async () => {
    try {
      const response = await axios.get('/admin/users?loginEnabled=true&roleType=client-admin', { headers });
      setAdmins({ data: response.data, fetched: true });
    } catch (e) {
      throwError(e);
    }
  };
  const getRoles = async () => {
    try {
      const response = await axios.get('/auth/acl-data', { headers });
      setRoles({
        data: Object.values(response.data.roles)
          .filter((r) => r.roleType === 'client-admin')
          .map((r) => ({ value: r._id, label: translate(r.name) })),
        fetched: true,
      });
    } catch (e) {
      throwError(e);
    }
  };
  const onChange = (e) => dispatch({ field: e.target.name, value: e.target.value });

  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredAdmins.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        });
      },
    },
    selects: [
      {
        name: 'role',
        className: 'role-selector',
        selected: searchAndFilter.role,
        onSelectChange: (selectValue) =>
          setSearchAndFilter({
            ...searchAndFilter,
            role: selectValue,
          }),
        options: roles.data,
      },
    ],
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };
  const componentName = 'admins';

  return (
    <div className="admins-container container">
      <SubHeader>
        <div className="column">
          <NavigateTabs routes={routes} />
        </div>
        <div className="column">
          <ViewOptions componentName={componentName} />
          <AuthComponentWrapper allowedRules={['create-admin']}>
            <button className="subheader-button" onClick={() => setPopupVisibility(true)}>
              <img src={add} />
              <Translate value="entity-header/create-new-admin" />
            </button>
          </AuthComponentWrapper>
        </div>
      </SubHeader>
      <Filterbar {...filterbarProps} />
      <AdminsTable
        roles={roles}
        history={history}
        filteredAdmins={filteredAdmins}
        setFilteredAdmins={setFilteredAdmins}
        componentName={componentName}
      />
      <Popup
        popupShown={isPopupOpen}
        popupTitle={I18n.t('entity-header/create-new-admin')}
        cancelHandler={cancelHandler}
        okHandler={okHandler}
        okDisabled={!formValid}
      >
        <span>{I18n.t('user-management/admins/popup-desc')}</span>
        <AdminForm setFormValid={setFormValid} onChange={onChange} user={formState} />
      </Popup>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(Admins), ['list-admins']);
