import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { i18nReducer, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import moment from 'moment';

import auth from './reducers/auth';
import entity from './reducers/entity';
import layout from './reducers/layout';
import modules from './reducers/modules';
import submissions from './reducers/submissions';
import error from './reducers/error';
import { getBrowserLang } from './helper/functions';

import { LANGUAGE } from './constants';

const persistedLocalStorageObject = localStorage.getItem('localStorageObject')
  ? JSON.parse(localStorage.getItem('localStorageObject'))
  : {};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(
  combineReducers({
    i18n: i18nReducer,
    error,
    auth,
    entity,
    layout,
    modules,
    submissions,
  }),
  {
    ...persistedLocalStorageObject,
  },
  enhancer
);

syncTranslationWithStore(store);

store.subscribe(() => {
  const lSobj = {
    auth: store.getState().auth,
    layout: store.getState().layout,
  };
  localStorage.setItem('localStorageObject', JSON.stringify(lSobj));
});

let currentLocale;
if (localStorage.getItem('currentLocale')) {
  currentLocale = localStorage.getItem('currentLocale');
}
moment.locale(currentLocale || getBrowserLang());
store.dispatch(setLocale(currentLocale || getBrowserLang()));

export default store;
