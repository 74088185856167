import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

import SearchBar from '../SearchBar';
import ReactSelectWrapper from '../ReactSelectWrapper/ReactSelectWrapper';

import './Filterbar.scss';

const Filterbar = (props) => {
  const { isFilterBarOpen, searchbar, switchers, selects, clearSearchAndFilter } = props;
  const { shown, resultsLength, type, placeholder, onSearchChange } = searchbar;

  useEffect(() => {
    if (!isFilterBarOpen) {
      clearSearchAndFilter();
    }
  }, [isFilterBarOpen]);

  if (!isFilterBarOpen) return null;

  return (
    <div className="filterbar">
      <div className="search-container">
        {shown && (
          <SearchBar
            resultsLength={resultsLength}
            type={type}
            placeholder={placeholder}
            onChange={(event) => onSearchChange(event.target.value)}
          />
        )}
      </div>
      <div className="filter-container">
        {switchers &&
          switchers.map((switcher, index) => (
            <div className="switcher-container" key={index}>
              {I18n.t(switcher.text)}
              <div className="switcher">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={switcher.checked}
                    onChange={(event) => switcher.onSwitcherChange(event.target.value)}
                  />
                  <span className={`slider round ${switcher.checked ? 'on' : 'off'}`}></span>
                </label>
              </div>
            </div>
          ))}
        {selects &&
          selects.map((select, index) => {
            const extendedOptions = [
              {
                value: 'all',
                label: I18n.t('general/all').replace('{}', I18n.t(`selector/${select.name}`).toLowerCase()),
              },
              ...select.options,
            ];
            const placeholder = I18n.t('selector/select', { name: I18n.t(`selector/${select.name}`) });
            return (
              <div key={index}>
                <ReactSelectWrapper
                  options={extendedOptions}
                  className={select.className}
                  onChange={(event) => select.onSelectChange(event.value)}
                  placeholder={placeholder}
                  hasHover={true}
                  value={{
                    value: select.selected,
                    label: select.selected
                      ? extendedOptions.find((o) => o.value === select.selected).label
                      : placeholder,
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isFilterBarOpen: state.layout.isFilterBarOpen,
  };
}

export default connect(mapStateToProps, null)(Filterbar);
