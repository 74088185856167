import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import ModuleSelectorPopup from '../ModuleSelectorPopup';
import BackButton from '../BackButton';
import Authorization from '../Authorization';
import TextWithTooltip from '../TextWithTooltip';
import SubHeader from '../SubHeader';
import ViewOptions from '../ViewOptions';
import Filterbar from '../Filterbar';

import * as errorActions from '../../actions/error';
import * as entityThunks from '../../thunks/entity';
import { translate, getDateInLocalFormat } from '../../helper/functions';
import { calculateRoutePath } from '../../helper/navigation';

import './ModuleSelector.scss';

const ModuleSelector = (props) => {
  const {
    entityId,
    fetchEntities,
    modules,
    filterAutoCreate,
    history,
    token,
    moduleId,
    subPageTitle,
    userData,
    throwError,
    numberOfEntities,
    isLoaded,
  } = props;
  const usersMainRoute = userData && userData.mainRoute;
  const pathname = history && history.location ? history.location.pathname : '';
  const defaultSearchAndFilter = { search: '' };
  const [searches, setSearches] = useState(defaultSearchAndFilter);
  const [popup, popupSetter] = useState({
    isPopupOpen: false,
    moduleId: null,
    submissionName: '',
  });
  const [filteredModules, setFilteredModules] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!pathname.includes('new-processes') && !isLoaded) {
      fetchEntities();
    }
  }, []);

  useEffect(() => {
    if (Object.values(modules).length) {
      fetchCategories();
    }
  }, [modules]);
  useEffect(() => {
    if (Object.values(modules).some((m) => m._id === moduleId)) {
      popupSetter({
        isPopupOpen: true,
        moduleId,
        submissionName: translate(modules[moduleId].label) + ' ' + new Date().toLocaleDateString(),
      });
    }
    return () => {
      popupSetter({ isPopupOpen: false, moduleId: null, submissionName: '' });
    };
  }, [modules]);
  useEffect(() => {
    setFilteredModules(
      Object.values(modules).filter(
        (m) =>
          (filterAutoCreate ? !m.autoCreateEntity : m.autoCreateEntity) &&
          (translate(m.description).toLowerCase().includes(searches.search.toLowerCase()) ||
            translate(m.label).toLowerCase().includes(searches.search.toLowerCase()))
      )
    );
  }, [modules, searches]);

  const headers = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  const fetchCategories = async () => {
    try {
      const response = await axios.get('/categories', headers);
      setCategories(
        response.data.filter((c) =>
          Object.values(modules)
            .filter((m) => (filterAutoCreate ? !m.autoCreateEntity : m.autoCreateEntity))
            .some((m) => m.categories.includes(c._id))
        )
      );
    } catch (e) {
      throwError(e);
    }
  };
  const createSubmission = () => {
    const requestObject = {
      moduleId: popup.moduleId,
      name: popup.submissionName,
    };
    if (entityId) {
      requestObject.entityId = entityId;
    }
    if (popup.submissionName.length === 0) {
      requestObject.name = translate(modules[popup.moduleId].label) + ' ' + getDateInLocalFormat(new Date());
    }
    let promises = [
      axios.get('/drf/modules/' + popup.moduleId, headers),
      axios.post('/drf/submissions', requestObject, headers),
    ];
    return Promise.all(promises)
      .then((response) => {
        history.push(
          calculateRoutePath(
            usersMainRoute,
            '/client-management/' + response[1].data._id + '/' + response[0].data.stageMeta[0].stageId
          )
        );
      })
      .catch((e) => {
        throwError(e);
      });
  };

  const renderButtons = (filteredModules) => {
    if (!filteredModules || !filteredModules.length) {
      return null;
    }

    return (
      <div className="cards-container">
        {filteredModules.map((item) => (
          <div className="card-wrapper" key={item._id}>
            <div
              className="card"
              onClick={() =>
                popupSetter({
                  isPopupOpen: true,
                  moduleId: item._id,
                  submissionName: translate(modules[item._id].label) + ' ' + getDateInLocalFormat(new Date()),
                })
              }
            >
              <div className="card-title">
                <TextWithTooltip textWithOverflow={translate(item.label)} textClassName="title" />
              </div>
              <span className="description" dangerouslySetInnerHTML={{ __html: translate(item.description) }}></span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredModules.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearches({
          ...searches,
          search: event.target.value,
        });
      },
    },
    clearSearchAndFilter: () => setSearches(defaultSearchAndFilter),
  };

  const backBtnTitle =
    numberOfEntities > 0 ? 'entity-details/sidebar/back-button' : 'entity-details/sidebar/back-button-no-entity';

  return (
    <div className="module-selector-container">
      <SubHeader>
        <div className="column">
          {!pathname.includes('new-processes') && (
            <BackButton
              title={I18n.t(backBtnTitle)}
              backUrl="/client-management/entities"
              className="negative-margin"
            />
          )}
          <div className="title">
            <TextWithTooltip textWithOverflow={subPageTitle} />
          </div>
        </div>
        <div className="column">
          <ViewOptions options={['toggleFilterBar']} />
        </div>
      </SubHeader>
      <Filterbar {...filterbarProps} />
      <div className="modules-container">
        {categories.length > 1
          ? categories.map((category) => (
              <div key={category._id} className="categories-container">
                <div className="category-title">{translate(category.label)}</div>
                {renderButtons(filteredModules.filter((m) => m.categories.includes(category._id)))}
              </div>
            ))
          : renderButtons(filteredModules)}
      </div>
      <ModuleSelectorPopup popup={popup} popupSetter={popupSetter} createSubmission={createSubmission} />
    </div>
  );
};

ModuleSelector.propTypes = {
  token: PropTypes.string,
  modules: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const entityId = ownProps && ownProps.params ? ownProps.params.entityId : null;
  const moduleId = ownProps.match && ownProps.match.params ? ownProps.match.params.moduleId : null;
  const numberOfEntities = state.entity && state.entity.entities && state.entity.entities.length;
  const isLoaded = state.entity && state.entity.isLoaded;

  return {
    token: state.auth.token,
    modules: state.modules.modules,
    userData: state.auth.userData,
    entityId,
    moduleId,
    numberOfEntities,
    isLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      throwError: errorActions.throwServerError,
      fetchEntities: entityThunks.fetchEntities,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(ModuleSelector), ['create-submission']);
